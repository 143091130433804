import React, { useState, useEffect } from "react";
import bookingIcon from '../../../../assets/img/png/booking-icon.png';
import airbnbIcon from '../../../../assets/img/png/airbnb_icon.png';
import {
  Switch,
  List,
  Avatar,
  Button,
  Modal as ModalAntd,
  notification,
  Calendar,
  DatePicker,
  Space,
  Icon,
  Divider,
  Row,
  Col,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import dayjs from "dayjs";
import {
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  CalendarOutlined,
  CommentOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import CopyToClipboard from "../../../CopyToClipboard";
import Modal from "../../../Modal";
import EditInquilinoForm from "../EditInquilinoForm";
import AddInquilinoForm from "../AddInquilinoForm";
import {
  getAvatarApi,
  updateInquilinoApi,
  deleteInquilinoApi,
} from "../../../../api/inquilino";
import { getAccessTokenApi } from "../../../../api/auth";

import "./Listinquilinosfacturacion.scss";
import {
  createInmueble,
  getDescriptionInmueble,
  getNombreInmueble,
} from "../../../../api/inmueble";
import { Column } from "@antv/g2plot";
import DesplegableMultiple from "../../../DesplegableMultiple";
import { useAuth } from "../../../../hooks";
import { basePathFrontend } from "../../../../api/config";

const { confirm } = ModalAntd;
export default function Listinquilinosfacturacion(props) {
  const {
    setSelectedValues,
    usersActive,
    usersInactive,
    setReloadUsers,
    reloadUsers,
  } = props;
  const [viewUsersActives, setViewUsersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const addUserModal = () => {
    setIsVisibleModal(true);

    setModalTitle("Creando nuevo usuario");
    setModalContent(
      <AddInquilinoForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };
  const handleSelectChange = (values) => {
    setSelectedValues(values);
  };
  const { setUser, users } = useAuth();
  return (
    <>
      {users.user.role === "Administrador" || users.user.role === "Gestor" ? (
        <>
          <Button type="primary" onClick={addUserModal}>
            Nuevo usuario
          </Button>
        </>
      ) : (
        <></>
      )}
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <UsersActive
          usersActive={usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
          setReloadUsers={setReloadUsers}
          reloadUsers={reloadUsers}
        />
      </div>
      <div style={{ display: isVisibleModal ? "block" : "none" }}>
        <Modal
          title={modalTitle}
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
        >
          {modalContent}
        </Modal>
      </div>
    </>
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadUsers,
    reloadUsers,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ?? "..."} 
      ${user.lastname ?? "..."}`
    );
    setModalContent(
      <EditInquilinoForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };
  const seeCalendar = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ?? "..."} 
      ${user.lastname ?? "..."}`
    );

    setModalContent();
  };
  const { setUser, users } = useAuth();
  return (
    <>
      <Divider />
      <div style={{ display: "table-row" }}>
      <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Estado
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Fecha entrada
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Fecha salida
        </div>
        
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Plataforma
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Pago
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Comision
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Pago limpieza
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          IVA (eur)
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          IVA (%)
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Nombre
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Numero personas
        </div>
        {users.user.role === "Administrador" || users.user.role === "Gestor" ? (
          <>
           <Divider type="vertical" />
           <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Numero IDS pdte
        </div>
          </>
        ) : (
          <></>
        )}
        {users.user.role === "Administrador" || users.user.role === "Gestor" ? (
          <>
            <Divider type="vertical" />
            <div
              style={{
                display: "table-cell",
                width: "14%",
                padding: "8px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Editar
            </div>
            <Divider />
            <div
              style={{
                display: "table-cell",
                width: "14%",
                padding: "8px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Borrar
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <List
        style={{ width: "100%" }}
        itemLayout="horizontal"
        dataSource={usersActive}
        renderItem={(user) => (
          <UserActive
            style={{ width: "100%", backgroundColor: "pink" }}
            seeCalendar={seeCalendar}
            user={user}
            editUser={editUser}
            setReloadUsers={setReloadUsers}
            reloadUsers={reloadUsers}
          />
        )}
      />
    </>
  );
}

function UserActive(props) {
  const { user, editUser, setReloadUsers, reloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [nombre_piso, setNombrePiso] = useState(null);

  const desactivateUser = () => {
    const accesToken = getAccessTokenApi();
    user.active = false;
    //console.log("desactivateUser", user);
    updateInquilinoApi(accesToken, user, user._id)
      .then((response) => {
        notification["success"]({
          message: "Desactivado",
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: "Problema desactivando",
        });
        setReloadUsers(true);
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${user.name}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteInquilinoApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: "Borrado con exito",
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };
  function abrirEnlace() {
    let enlace =
    basePathFrontend+"/acceder/" +
      user.code +
      "%0d%0a(Válido desde el " +
      user.fecha_entrada +
      " hasta el " +
      user.fecha_salida +
      ")";
    const accessToken = getAccessTokenApi();
    getDescriptionInmueble(user.inmueble, accessToken)
      .then((response) => {
        window.open(
          "https://api.whatsapp.com/send?phone=" +
            user.telefono +
            "&text=" +
            response.description.replace("@enlace_inquilino", enlace),
          "_blank"
        );
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  }
  useEffect(() => {
    const token = getAccessTokenApi();
    getNombreInmueble(user.inmueble, token).then((response) => {
      setNombrePiso(response.message);
    });
  }, [reloadUsers, user.inmueble]);

  const { RangePicker } = DatePicker;

  const now = moment(); // obtiene la fecha actual
  let mystyle;
  let arrow = 0;
  if (moment(user.fecha_entrada, "YYYY-MM-DD").isSame(now, "day")) {
    mystyle = { color: "green" };
    arrow = 0;
  } else if (moment(user.fecha_salida, "YYYY-MM-DD").isSame(now, "day")) {
    mystyle = { color: "red" };

    arrow = 1;
  } else {
    mystyle = { color: "white" };
    arrow = 2;
  }
  const { setUser, users } = useAuth();
  // //console.log(now.format('YYYY-MM-DD')); // muestra la fecha actual en formato YYYY-MM-DD
  return (
    <List.Item style={{ width: "100%", display: "table" }}>
      <div style={{ display: "table-row" }}>
      <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.status}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.fecha_entrada}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.fecha_salida}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.plataforma === 'Booking' || user.plataforma === 'Booking.com' ? (
            <img src={bookingIcon} alt="Booking Icon" style={{ width: '20px', height: '20px' }} />
          ) :  user.plataforma === 'Airbnb'?(
            <img src={airbnbIcon} alt="Airbnb Icon" style={{ width: '20px', height: '20px' }} />
          ): "Referido"}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {parseFloat(user.pago).toFixed(2)}
        </div>
        <Divider type="vertical" />

        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {parseFloat(user.comision).toFixed(2)}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {parseFloat(user.pago_limpieza).toFixed(2)}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.taxes_for_invoicing ?? "0.0"}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.tax_percentage ?? "0.0"} %
        </div>

        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.name} {user.lastname}
        </div>
        <Divider type="vertical" />
        <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.numero_personas}
        </div>
        {users.user.role === "Administrador" || users.user.role === "Gestor" ? (
        <>
         <div
          style={{
            display: "table-cell",
            width: "14%",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {user.numero_ids_enviados}
        </div>
        </>):(<></>)}
        {users.user.role === "Administrador" || users.user.role === "Gestor" ? (
          <>
            <Divider type="vertical" />
            <div
              style={{
                display: "table-cell",
                width: "14%",
                padding: "8px",
                textAlign: "center",
              }}
            >
              <Button type="primary" onClick={() => editUser(user)}>
                <EditOutlined />
              </Button>
            </div>
            <Divider type="vertical" />
            <div
              style={{
                display: "table-cell",
                width: "14%",
                padding: "8px",
                textAlign: "center",
              }}
            >
              <Button
                type="primary"
                onClick={showDeleteConfirm}
                label="Desactivar Inquilino"
                style={{ backgroundColor: "red", borderColor: "red" }}
              >
                <CloseCircleOutlined />
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </List.Item>
  );
}
