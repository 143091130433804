import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Divider,
  Carousel,
  Collapse,
  Checkbox,
} from "antd";
import { useDropzone } from "react-dropzone";

import { updateInmuebleApi } from "../../../../../api/inmueble";

import moment from "moment";
import NoAvatar from "../../../../../assets/img/png/no-avatar.png";
import {
  updateUserApi,
  uploadAvatarApi,
  getAvatarApi,
} from "../../../../../api/user";
import { getAccessTokenApi } from "../../../../../api/auth";
import "./EditInmuebleForm.scss";
import InventoryList from "./InventoryList";
import { getSeries } from "../../../../../api/series";

export default function EditInmuebleForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});
  
  //console.log("EditUserForm");
  //console.log(user.fecha_entrada, user.fecha_salida);
  useEffect(() => {
    setUserData({
      name: user.name,
      coste_limpieza: user.coste_limpieza,
      comision: user.comision,
      fecha_fin_gestion: user.fecha_fin_gestion,
      fecha_inicio_gestion: user.fecha_inicio_gestion,
      telefono: user.telefono,
      owner: user.owner,
      id_electronica_portal: user.id_electronica_portal,
      id_sensor_temperatura: user.id_sensor_temperatura,
      id_actuador_caldera: user.id_actuador_caldera,
      nuki_id: user.nuki_id,
      fecha_entrada: user.fecha_entrada,
      fecha_salida: user.fecha_salida,
      wifi_ssid: user.wifi_ssid,
      wifi_pass: user.wifi_pass,
      router_user: user.router_user,
      router_pass: user.router_pass,
      description: user.description,
      limpiador_telefono: user.limpiador_telefono,
      limpiador_nombre: user.limpiador_nombre,
      nombre_facturacion: user.nombre_facturacion,
      id_facturacion: user.id_facturacion,
      direccion_facturacion: user.direccion_facturacion,
      group_id: user.group_id,
      tipo_gestion: user.tipo_gestion,

      description_frances: user.description_frances,
      description_ingles: user.description_ingles,

      mensaje_pre_entrada: user.mensaje_pre_entrada,
      mensaje_pre_entrada_frances: user.mensaje_pre_entrada_frances,
      mensaje_pre_entrada_ingles: user.mensaje_pre_entrada_ingles,

      mensaje_post_salida: user.mensaje_post_salida,
      mensaje_post_salida_ingles: user.mensaje_post_salida_ingles,
      mensaje_post_salida_frances: user.mensaje_post_salida_frances,

      user_hospederias: user.user_hospederias,
      password_hospederias: user.password_hospederias,
      cerradura_id: user.cerradura_id,

      numero_habitaciones: user.numero_habitaciones,
      numero_camas_90: user.numero_camas_90,
      numero_camas_150: user.numero_camas_150,
      numero_sofas_cama_150: user.numero_sofas_cama_150,
      numero_sofas_cama_90: user.numero_sofas_cama_90,

      numero_licencia_turistica: user.numero_licencia_turistica,
      iban: user.iban,

      stock_sabanas_150: user.stock_sabanas_150,
      stock_sabanas_90: user.stock_sabanas_90,
      stock_edredones_90: user.stock_edredones_90,
      stock_edredones_150: user.stock_edredones_150,
      stock_toallas_cuerpo: user.stock_toallas_cuerpo,
      stock_toallas_mano: user.stock_toallas_mano,
      stock_alfombrines: user.stock_alfombrines,
      stock_almohadas: user.stock_almohadas,

      stock_optimo_sabanas_150: user.stock_optimo_sabanas_150,
      stock_optimo_sabanas_90: user.stock_optimo_sabanas_90,
      stock_optimo_edredones_90: user.stock_optimo_edredones_90,
      stock_optimo_edredones_150: user.stock_optimo_edredones_150,
      stock_optimo_toallas_cuerpo: user.stock_optimo_toallas_cuerpo,
      stock_optimo_toallas_mano: user.stock_optimo_toallas_mano,
      stock_optimo_alfombrines: user.stock_optimo_alfombrines,
      stock_optimo_almohadas: user.stock_optimo_almohadas,

      coste_personal_limpieza : user.coste_personal_limpieza,
      coste_festivo_limpieza : user.coste_festivo_limpieza,
      gastos_lavanderia_piso: user.gastos_lavanderia_piso,
      gastos_transportista: user.gastos_transportista,
      direccion_inmueble : user.direccion_inmueble,
      latitud : user.latitud,
      longitud : user.longitud,
      url_booking : user.url_booking,
      gastos_fijos_subarriendo:user.gastos_fijos_subarriendo,
      ciudad: user.ciudad,

      comision_airbnb: user.comision_airbnb,
      comision_booking: user.comision_booking,
      codigo_huespedes: user.codigo_huespedes,
      codigo_limpieza: user.codigo_limpieza,
      gastos_estimados_suministros: user.gastos_estimados_suministros,
      inventario: user.inventario,
      empresa_energia_electrica: user.empresa_energia_electrica,
      empresa_gas: user.empresa_gas,
      propietario_paga_limpieza: user.propietario_paga_limpieza ?? false,
      generar_factura_propietario: user.generar_factura_propietario ??false,

    //Series: 
    //Subarriendo:
      correo_electronico_propietario_enviar_facturas : user.correo_electronico_propietario_enviar_facturas,
      
      serie_propietario_a_empresa_gestora: user.serie_propietario_a_empresa_gestora??null, //Cuando le hacemos la factura al propitario para nosotros en su nombre
      serie_empresa_gestora_a_inquilino_pide_factura :user.serie_empresa_gestora_a_inquilino_pide_factura??null ,
      serie_empresa_gestora_a_clientes_varios :user.serie_empresa_gestora_a_clientes_varios??null ,
      
      serie_propietario_a_empresa_gestora_rectificativa: user.serie_propietario_a_empresa_gestora_rectificativa??null,
      serie_empresa_gestora_a_inquilino_pide_factura_rectificativa :user.serie_empresa_gestora_a_inquilino_pide_factura_rectificativa??null ,
      serie_empresa_gestora_a_clientes_varios_rectificativa :user.serie_empresa_gestora_a_clientes_varios_rectificativa??null ,
  
    //Gestion
    enviar_correo_a_propietario_tras_generar_cada_factura:user.enviar_correo_a_propietario_tras_generar_cada_factura,
      serie_propietario_a_inquilino_pide_factura :user.serie_propietario_a_inquilino_pide_factura??null ,
      serie_propietario_a_inquilino_pide_factura_rectificativa :user.serie_propietario_a_inquilino_pide_factura_rectificativa??null ,
      serie_propietario_a_clientes_varios :user.serie_propietario_a_clientes_varios??null ,
      serie_propietario_a_clientes_varios_rectificativa :user.serie_propietario_a_clientes_varios_rectificativa??null ,
      serie_factura_mi_gestion_a_propietario :user.serie_factura_mi_gestion_a_propietario??null,
      serie_factura_mi_gestion_a_propietario_rectificativa :user.serie_factura_mi_gestion_a_propietario_rectificativa??null,

    });
  }, [user]);


  




  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  useEffect(() => {
    if (avatar) {
      setUserData({ ...userData, avatar: avatar.file });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  const updateUser = (e) => {
    //console.log("updateUser", e);
    //e.preventDefault();
    const token = getAccessTokenApi();
    let userUpdate = userData;
    //console.log(userData);
    if (!userData.name) {
      notification["error"]({
        message: "Falta el nombre",
      });
      return;
    }

    updateInmuebleApi(token, userUpdate, user._id).then((result) => {
      notification["success"]({
        message: "Inmueble actualizado",
      });
      setIsVisibleModal(false);
      setReloadUsers(true);
    });
    // if (typeof userUpdate.avatar === "object") {
    //   //console.log("updateUserApi3");
    //   uploadAvatarApi(token, userUpdate.avatar, user._id).then((response) => {
    //     //console.log("updateUserApi2");
    //     userUpdate.avatar = response.avatarName;
    //     updateUserApi(token, userUpdate, user._id).then((result) => {
    //       notification["success"]({
    //         message: result.message,
    //       });
    //       setIsVisibleModal(false);
    //       setReloadUsers(true);
    //     });
    //   });
    // } else {
    //   //console.log("updateUserApi");
    //   updateUserApi(token, userUpdate, user._id).then((result) => {
    //     notification["success"]({
    //       message: result.message,
    //     });
    //     setIsVisibleModal(false);
    //     setReloadUsers(true);
    //   });
    // }
    // };
    //console.log("EditUserForm return");
  };
  return (
    <div className="edit-user-form">
      <UploadAvatar avatar={avatar} setAvatar={setAvatar} />
      <EditForm
        userData={userData}
        setUserData={setUserData}
        updateUser={updateUser}
      />
    </div>
  );
}

function UploadAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={NoAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : NoAvatar} />
      )}
    </div>
  );
}



//EDIT FORM

function EditForm(props) {

  const { userData, setUserData, updateUser } = props;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const [series, setseries] = useState({});
  const [map_series, setmap_series] = useState({});
 


  useEffect(()=>{
    getSeries(getAccessTokenApi()).then((response) => {
      var series_elementos = [];
      var series_elementos_aux = {};
      series_elementos.push(
        <Option value={null}>
         - NULL - (USAR CUANDO NO QUIERO SELECCIONAR NINGUNA SERIE)
        </Option>
      );
      //console.log(response);
      for (let i = 0; i < response.series.length; i++) {
        series_elementos_aux[response.series[i]._id] = response.series[i];
        series_elementos.push(
          <Option value={response.series[i]._id}>
            {response.series[i].nombre_serie}
          </Option>
        );
     
      }
     
      //console.log(numeros_series);
      setmap_series(series_elementos_aux)
      setseries(series_elementos);
    });
  },[])


  //console.log("FORMULARIO");
  //console.log(userData);
  //console.log(moment(userData.fecha_entrada, "YYYY-MM-DD"));
  const onChange = (dates) => {
    //console.log("onChange");
    //console.log(dates[0]);
    //console.log(dates[1]);
    setUserData({
      ...userData,
      fecha_entrada: dates[0].format("YYYY-MM-DD"),

      fecha_salida: dates[1].format("YYYY-MM-DD"),
    });
  };
  const { TextArea } = Input;
  const [tipo_gestion, settipo_gestion] = useState("Gestion_neto");
  const handleSelectChange = (values) => {
    setUserData({ ...userData, tipo_gestion: values });
  };
  function handleInventoryChange(values){
    setUserData({ ...userData, inventario: values });
  }
  return (
    <Form className="form-edit" onFinish={updateUser}>
      <span>Group ID: {userData.group_id}</span>
      <Collapse >
      <Collapse.Panel header="Tipo de gestion" key="Gestion">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Tipo de gestión
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={userData.tipo_gestion}
            // style={{ width: 120 }}
            onChange={handleSelectChange}
            options={[
              { value: "Gestion_neto", label: "Gestion_neto" },
              { value: "Gestion_bruta", label: "Gestion_bruta" },
              { value: "Propio", label: "Propio" },
              { value: "Subarriendo", label: "Subarriendo" },
              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Incio gestion">
            <RangePicker
              value={[
                moment(
                  userData.fecha_inicio_gestion ??
                  moment().format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ),
                moment(
                  userData.fecha_fin_gestion ?? moment().format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ),
              ]}
              renderExtraFooter={() => "extra footer"}
              onChange={(dates) => {
                setUserData({
                  ...userData,
                  fecha_inicio_gestion: dates[0].format("YYYY-MM-DD"),

                  fecha_fin_gestion: dates[1].format("YYYY-MM-DD"),
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comisión por gestión
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Comision por gestión"
              value={userData.comision}
              onChange={(e) =>
                setUserData({ ...userData, comision: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ¿Propietario paga limpieza?
        </Col>

        <Col span={12}>
          <Form.Item>
         
        
            <Checkbox
              // style={{ border: '1px solid #d9d9d9', borderRadius: '5px', padding: '10px', display: 'inline-block' }}
              checked={userData.propietario_paga_limpieza ?? false}
              onChange={(e) =>
                setUserData({ ...userData, propietario_paga_limpieza: e.target.checked })
              }
            >   
            </Checkbox>
            Propietario paga limpieza
          


          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Alquiler subarriendo con IVA
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gastos fijos subarriendo"
              value={userData.gastos_fijos_subarriendo}
              onChange={(e) =>
                setUserData({ ...userData, gastos_fijos_subarriendo: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Suministros al mes con IVA
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="gastos_estimados_suministros"
              value={userData.gastos_estimados_suministros}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  gastos_estimados_suministros: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Divider>Calcular limpieza</Divider>
       <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto personal limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto personal limpieza"
              value={userData.coste_personal_limpieza}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  coste_personal_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Suplemento por dia festivo
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Suplemento por dia festivo"
              value={userData.coste_festivo_limpieza}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  coste_festivo_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto material limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto productos limpieza"
              value={userData.coste_productos_limpieza}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  coste_productos_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto Transporte/reserva
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto Transporte/reserva"
              value={userData.gastos_transportista}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  gastos_transportista: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto Lavanderia/reserva
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto Lavanderia"
              value={userData.gastos_lavanderia_piso}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  gastos_lavanderia_piso: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
     
      <Row>
  <Col
    span={12}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    TOTAL COSTE LIMPIEZA CON IVA:
  </Col>
  <Col span={12}>
    {(() => {
      // Convertir los valores a números y asegurarse de que no sean nulos o undefined
      const costePersonalLimpieza = Number(userData.coste_personal_limpieza) || 0;
      const costeProductosLimpieza = Number(userData.coste_productos_limpieza) || 0;
      const gastosLavanderiaPiso = Number(userData.gastos_lavanderia_piso) || 0;
      const gastosTransportista = Number(userData.gastos_transportista) || 0;

      // Calcular el costo total con IVA
      const totalCosteLimpieza = 
        costePersonalLimpieza +
        costeProductosLimpieza +
        gastosLavanderiaPiso +
        gastosTransportista;

      return `${totalCosteLimpieza} €`;
    })()}
  </Col>
</Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <b>Cobro a cliente por limpieza (Esto lo ve el propietario)</b>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto limpieza"
              value={userData.coste_limpieza}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  coste_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
  <Col
    span={12}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    Margen:
  </Col>
  <Col span={12}>
    {(() => {
      // Convertir los valores a números y asegurarse de que no sean nulos o undefined
      const costeLimpieza = Number(userData.coste_limpieza) || 0;
      const costePersonalLimpieza = Number(userData.coste_personal_limpieza) || 0;
      const costeProductosLimpieza = Number(userData.coste_productos_limpieza) || 0;
      const gastosLavanderiaPiso = Number(userData.gastos_lavanderia_piso) || 0;
      const gastosTransportista = Number(userData.gastos_transportista) || 0;

      // Calcular los costos totales
      const totalGastos =
        costePersonalLimpieza +
        costeProductosLimpieza +
        gastosLavanderiaPiso +
        gastosTransportista;

      // Calcular el margen si `coste_limpieza` y `totalGastos` son diferentes de 0
      const margen =
        costeLimpieza !== 0 && totalGastos !== 0
          ? ((costeLimpieza - totalGastos) / costeLimpieza) * 100
          : "-";

      return `${Number(margen).toFixed(2)} %`;
    })()}
  </Col>
</Row>

   
      </Collapse.Panel>
      <Collapse.Panel header="Datos del Inmueble" key="datos">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre Inmueble
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Nombre Inmueble"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Direccion inmueble
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Direccion inmueble"
              value={userData.direccion_inmueble}
              onChange={(e) =>
                setUserData({ ...userData, direccion_inmueble: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Ciudad
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ciudad"
              value={userData.ciudad}
              onChange={(e) =>
                setUserData({ ...userData, ciudad: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>  
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº licencia turística
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="numero_licencia_turistica"
              value={userData.numero_licencia_turistica}
              onChange={(e) =>
                setUserData({ ...userData, numero_licencia_turistica: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>  
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Latitud
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Latitud"
              value={userData.latitud}
              onChange={(e) =>
                setUserData({ ...userData, latitud: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Longitud
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Longitud"
              value={userData.longitud}
              onChange={(e) =>
                setUserData({ ...userData, longitud: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Url Booking
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Url Booking"
              value={userData.url_booking}
              onChange={(e) =>
                setUserData({ ...userData, url_booking: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>        
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Telefono
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono propietario"
              value={userData.telefono}
              onChange={(e) =>
                setUserData({ ...userData, telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Propietario Nombre
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Dueño"
              value={userData.owner}
              onChange={(e) =>
                setUserData({ ...userData, owner: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Usuario Hospederias
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Usuario hospederias"
              value={userData.user_hospederias}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  user_hospederias: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Password Hospederias
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Password hospederias"
              value={userData.password_hospederias}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  password_hospederias: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Empresa energia electrica
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="empresa_energia_electrica"
              value={userData.empresa_energia_electrica}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Empresa de gas
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Empresa de gas"
              value={userData.empresa_gas}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>


      </Collapse.Panel>
      <Collapse.Panel header="Comisiones" key="Comisiones">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comisiones Airbnb
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Item>
          <Input
            //prefix={<Icon type="mail" />}
            placeholder="Comisiones Airbnb"
            value={userData.comision_airbnb}
            onChange={(e) => {
              
              
                setUserData({
                  ...userData,
                  comision_airbnb: e.target.value,
                });
              
            }}
          />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comisiones Booking
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Item>
          <Input
            //prefix={<Icon type="mail" />}
            placeholder="Comisiones Booking"
            value={userData.comision_booking}
            onChange={(e) => {
              
             
                setUserData({
                  ...userData,
                  comision_booking: e.target.value,
                });
              
            }}
          />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Limpieza" key="Limpiezas">
     
      
      
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre de limpiador@
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Nombre del limpiador@"
              value={userData.limpiador_nombre}
              onChange={(e) =>
                setUserData({ ...userData, limpiador_nombre: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Telefono de limpiador
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono del limpiador"
              value={userData.limpiador_telefono}
              onChange={(e) =>
                setUserData({ ...userData, limpiador_telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      </Collapse.Panel>
      <Collapse.Panel header="WIFI" key="WIFI">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Wifi SSID
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Wifi SSID"
              value={userData.wifi_ssid}
              onChange={(e) =>
                setUserData({ ...userData, wifi_ssid: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Wifi Pass
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Wifi Pass"
              value={userData.wifi_pass}
              onChange={(e) =>
                setUserData({ ...userData, wifi_pass: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Router user
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Router user"
              value={userData.router_user}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  router_user: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Router pass
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Router pass"
              value={userData.router_pass}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  router_pass: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Camas" key="Camas">

     
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº habitaciones
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero habitaciones"
              value={userData. numero_habitaciones}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  numero_habitaciones: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Camas de 90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero camas 90"
              value={userData.numero_camas_90}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  numero_camas_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Camas de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero camas 150"
              value={userData.numero_camas_150}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  numero_camas_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Sofas cama de 90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero camas 90"
              value={userData.numero_sofas_cama_90}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  numero_sofas_cama_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Sofas cama de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero sofas cama 150"
              value={userData.numero_sofas_cama_150}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  numero_sofas_cama_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Inventario" key="Inventario">

        <InventoryList initialData={userData.inventario} onInventoryChange={handleInventoryChange}/>
      </Collapse.Panel>
      <Collapse.Panel header="Stock Sabanas" key="StockSabanas">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_sabanas_90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="stock_sabanas_90"
              value={userData.stock_sabanas_90}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_sabanas_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Sabanas de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock sabanas 150"
              value={userData.stock_sabanas_150}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_sabanas_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_edredones_90

        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="
              stock_edredones_90"
              value={userData.stock_edredones_90}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_edredones_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_edredones_150

        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="stock_edredones_150"
              value={userData.stock_edredones_150}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_edredones_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock almohadas
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock almohadas"
              value={userData.stock_almohadas}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_almohadas: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Toallas Cuerpo
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock toallas cuerpo"
              value={userData.stock_toallas_cuerpo}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_toallas_cuerpo: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Toallas Mano
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock toallas mano"
              value={userData.stock_toallas_mano}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_toallas_mano: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Alfombrines
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock alfombrines"
              value={userData.stock_alfombrines}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_alfombrines: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Stock Sabanas Optimo" key="StockSabanasOptimo">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_optimo_sabanas_90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="stock_optimo_sabanas_90"
              value={userData.stock_optimo_sabanas_90}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_sabanas_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Sabanas de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock sabanas 150"
              value={userData.stock_optimo_sabanas_150}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_sabanas_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_optimo_edredones_90

        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="
              stock_optimo_edredones_90"
              value={userData.stock_optimo_edredones_90}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_edredones_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_optimo_edredones_150

        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="stock_optimo_edredones_150"
              value={userData.stock_optimo_edredones_150}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_edredones_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock almohadas
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock almohadas"
              value={userData.stock_optimo_almohadas}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_almohadas: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Toallas Cuerpo
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock toallas cuerpo"
              value={userData.stock_optimo_toallas_cuerpo}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_toallas_cuerpo: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Toallas Mano
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock toallas mano"
              value={userData.stock_optimo_toallas_mano}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_toallas_mano: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Alfombrines
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock alfombrines"
              value={userData.stock_optimo_alfombrines}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  stock_optimo_alfombrines: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Electronica" key="Electronica">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Codigo Limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Limpieza abra la puerta"
              value={userData?.codigo_limpieza ?? ""}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  codigo_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Codigo Huespedes
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Huespedes abran puerta"
              value={userData.codigo_huespedes}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  codigo_huespedes: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID Nuki
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID Nuki Puerta Casa"
              value={userData.nuki_id}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  nuki_id: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID cerradura
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID Puerta Casa"
              value={userData.cerradura_id}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  cerradura_id: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID portal
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID portal"
              value={userData.id_electronica_portal}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  id_electronica_portal: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID medidor temperatura
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID medidor temperatura"
              value={userData.id_sensor_temperatura}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  id_sensor_temperatura: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID caldera actuador
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID caldera actuador"
              value={userData.id_actuador_caldera}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  id_actuador_caldera: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Facturacion" key="facturacion">

{ userData.tipo_gestion === "Subarriendo" ? <>
      SUBARRIENDO
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie factura del propietario a mi (empresa gestora)
        </Col>
        <Col span={12}>

        
    
        <Form.Item>
              <Select
               value={map_series[userData?.serie_propietario_a_empresa_gestora]?.nombre_serie}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  serie_propietario_a_empresa_gestora: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie RECTIFICATIVA factura del propietario a mi (empresa gestora)
        </Col>
        <Col span={12}>

        
    
        <Form.Item>
              <Select
               value={map_series[userData?.serie_propietario_a_empresa_gestora_rectificativa]?.nombre_serie}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  serie_propietario_a_empresa_gestora_rectificativa: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie yo (empresa gestora) a clientes concreto que pide factura
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_empresa_gestora_a_inquilino_pide_factura]?.nombre_serie}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  serie_empresa_gestora_a_inquilino_pide_factura: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie RECTIFICATIVA yo (empresa gestora) a clientes concreto que pide factura
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_empresa_gestora_a_inquilino_pide_factura_rectificativa]?.nombre_serie}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  serie_empresa_gestora_a_inquilino_pide_factura_rectificativa: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie yo (empresa gestora) a clientes varios
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_empresa_gestora_a_clientes_varios]?.nombre_serie}
              onChange={(e) => {
                
                setUserData({
                  ...userData,
                  serie_empresa_gestora_a_clientes_varios: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie RECTIFICATIVA yo (empresa gestora) a clientes varios
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_empresa_gestora_a_clientes_varios_rectificativa]?.nombre_serie}
              onChange={(e) => {
                
                setUserData({
                  ...userData,
                  serie_empresa_gestora_a_clientes_varios_rectificativa: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      
      <Divider></Divider>
      </>:
      <>
      GESTION
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Series de los propietarios a los clientes que piden factura
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_propietario_a_inquilino_pide_factura]?.nombre_serie}
              onChange={(e) => {
                console.log(map_series)
                setUserData({
                  ...userData,
                  serie_propietario_a_inquilino_pide_factura: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie RECTIFICATIVA de los propietarios a los clientes que piden factura
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_propietario_a_inquilino_pide_factura_rectificativa]?.nombre_serie}
              onChange={(e) => {
                console.log(map_series)
                setUserData({
                  ...userData,
                  serie_propietario_a_inquilino_pide_factura_rectificativa: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Series de los propietarios a clientes varios
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_propietario_a_clientes_varios]?.nombre_serie}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  serie_propietario_a_clientes_varios: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <br></br> 
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Series RECTIFICATIVA de los propietarios a clientes varios
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
               value={map_series[userData?.serie_propietario_a_clientes_varios_rectificativa]?.nombre_serie}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  serie_propietario_a_clientes_varios_rectificativa: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie factura del propietario a mi (empresa gestora)
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
              value={map_series[userData?.serie_factura_mi_gestion_a_propietario]?.nombre_serie}
              onChange={(e) => {
                
                setUserData({
                  ...userData,
                  serie_factura_mi_gestion_a_propietario: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Serie RECTIFICATIVA factura del propietario a mi (empresa gestora)
        </Col>
        <Col span={12}>

        <Form.Item>
              <Select
              value={map_series[userData?.serie_factura_mi_gestion_a_propietario_rectificativa]?.nombre_serie}
              onChange={(e) => {
                
                setUserData({
                  ...userData,
                  serie_factura_mi_gestion_a_propietario_rectificativa: e,
                })
              }}
              placeholder={"Selecciona una serie"}>
              {series}
            </Select>      
            
          </Form.Item>
         
        </Col>
      </Row>
      
      <Divider></Divider>
      
      </>}
      
      <Divider>Piso en subarriendo, ¿Hay que hacer factura al propietario?</Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ¿Hacer factura a final de mes?
        </Col>
        <Col span={12}>

        <Form.Item>
            <Checkbox
              // style={{ border: '1px solid #d9d9d9', borderRadius: '5px', padding: '10px', display: 'inline-block' }}
              checked={userData.generar_factura_propietario ?? false}
              onChange={(e) =>
                setUserData({ ...userData, generar_factura_propietario: e.target.checked })
              }
            >   
            </Checkbox>
            
          </Form.Item>
         
        </Col>
      </Row>
      <Divider>Enviar correos automaticos</Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Enviar Correo al propietarios tras cada factura
        </Col>

        <Col span={12}>
        <Form.Item>
            <Checkbox
              // style={{ border: '1px solid #d9d9d9', borderRadius: '5px', padding: '10px', display: 'inline-block' }}
              checked={userData.enviar_correo_a_propietario_tras_generar_cada_factura ?? false}
              onChange={(e) =>
                setUserData({ ...userData, enviar_correo_a_propietario_tras_generar_cada_factura: e.target.checked })
              }
            >   
            </Checkbox>
            
          </Form.Item>
         
         
        </Col>
      </Row>
      
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Correo del propietario para enviar las facturas
        </Col>

        <Col span={12}>
       
        
    
        <Form.Item>
        <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: pedrio@gmail.com"
              value={userData.correo_electronico_propietario_enviar_facturas}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  correo_electronico_propietario_enviar_facturas: e.target.value,
                })
              }
            />
            
          </Form.Item>
         
        </Col>
      </Row>
     <Divider>Datos facturacion</Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          NIF, CIF, VAT
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: DNI o CIF empresa"
              value={userData.id_facturacion}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  id_facturacion: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
 
      
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre empresa/persona fisica
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: Amazon S.L. / Pedro Jimenez"
              value={userData.nombre_facturacion}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  nombre_facturacion: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Direccion de facturacion
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: Avenida ... Nº .."
              value={userData.direccion_facturacion}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  direccion_facturacion: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Iban para ingresos propietario
        </Col>

        
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ES94XXXXX"
              value={userData.iban}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  iban: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      
      <Collapse.Panel header="Mensajes Huespedes" key="mensajes">ç
      <Collapse>
      <Collapse.Panel header="Mensaje Pre entrada" key="pre-entrada"> 
      
      <span>
        Palabras claves: @nombre_inquilino, @fecha_entrada, @fecha_salida,
        @formulario_ids
      </span>
      <Carousel class="Carousel">
        <div>
          <TextArea
            rows={20}
            placeholder="Ejemplo: Debes enviar tu dni para poder entrada"
            maxLength={4000}
            value={userData.mensaje_pre_entrada}
            onChange={(e) =>
              setUserData({
                ...userData,
                mensaje_pre_entrada: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Example: You must send your ID to be able to enter"
            maxLength={4000}
            value={userData.mensaje_pre_entrada_ingles}
            onChange={(e) =>
              setUserData({
                ...userData,
                mensaje_pre_entrada_ingles: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Exemple : Vous devez envoyer votre pièce d'identité pour pouvoir entrer"
            maxLength={4000}
            value={userData.mensaje_pre_entrada_frances}
            onChange={(e) =>
              setUserData({
                ...userData,
                mensaje_pre_entrada_frances: e.target.value,
              })
            }
          />
        </div>
      </Carousel>
      </Collapse.Panel>
      <Collapse.Panel header="Instrucciones" key="Instrucciones">
      
      <span>
        Palabras claves: @enlace_inquilino, @codigo_nuki, @nombre_inquilino, @codigo_huespedes
      </span>
      <Carousel class="Carousel">
        <div>
          <TextArea
            rows={30}
            placeholder="Describe el piso y sus alrededores en 4000 caracteres"
            maxLength={4000}
            value={userData.description}
            onChange={(e) =>
              setUserData({
                ...userData,
                description: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={30}
            placeholder="Describe the apartment and its sourroundings in 4000 caracters"
            maxLength={4000}
            value={userData.description_ingles}
            onChange={(e) =>
              setUserData({
                ...userData,
                description_ingles: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={30}
            placeholder="Décrivez l'appartement et ses environs en 4000 caractères"
            maxLength={4000}
            value={userData.description_frances}
            onChange={(e) =>
              setUserData({
                ...userData,
                description_frances: e.target.value,
              })
            }
          />
        </div>
      </Carousel>
      </Collapse.Panel>
      <Collapse.Panel header="Post-salida" key="Post-salida">
      <span>Palabras claves: @nombre_inquilino</span>
      <Carousel class="Carousel">
        <div>
          <TextArea
            rows={20}
            placeholder="Ejemplo: ¿Ha ido bien la salida? ¿Qué tal la estancia? Si no te importa valoranos."
            maxLength={4000}
            value={userData.mensaje_post_salida}
            onChange={(e) =>
              setUserData({
                ...userData,
                mensaje_post_salida: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Example: Did the start go well? How was the stay? If you don't mind rate us."
            maxLength={4000}
            value={userData.mensaje_post_salida_ingles}
            onChange={(e) =>
              setUserData({
                ...userData,
                mensaje_post_salida_ingles: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Exemple : Le démarrage s'est-il bien passé ? Comment s'est passé le séjour ? Si cela ne vous dérange pas, évaluez-nous."
            maxLength={4000}
            value={userData.mensaje_post_salida_frances}
            onChange={(e) =>
              setUserData({
                ...userData,
                mensaje_post_salida_frances: e.target.value,
              })
            }
          />
        </div>
      </Carousel>
      </Collapse.Panel>
      </Collapse>
      </Collapse.Panel>
      </Collapse>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Actualizar Inmueble
        </Button>
      </Form.Item>
    </Form>
  );
}
