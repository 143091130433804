import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Avatar,
  Button,
  Modal as ModalAntd,
  notification,
  Calendar,
  DatePicker,
  Space,
  Icon,
  Divider,
  Row,
  Col,
  TimePicker,
  Checkbox,
  Radio,
  Input,
  Collapse,
  Tooltip,
  Tabs
}
  from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import dayjs from "dayjs";
import {
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  CalendarOutlined,
  CommentOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  NotificationOutlined,
  RollbackOutlined,
  ExclamationOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  AlertOutlined,
  BellOutlined,
} from "@ant-design/icons";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import CopyToClipboard from "../../../CopyToClipboard";
import CopyToClipboard_v2 from "../../../CopyToClipboard_v2";
import Modal from "../../../Modal";
import EditInquilinoForm from "../EditInquilinoForm";
import AddInquilinoForm from "../AddInquilinoForm";
import {
  getAvatarApi,
  updateInquilinoApi,
  deleteInquilinoApi,
  postNewEstadoFlujo,
  avaibookCargarInquilinos
} from "../../../../api/inquilino";
import { getAccessTokenApi } from "../../../../api/auth";
import UserInquilinosDni from "./UserInquilinosDni";

import "./Listinquilinos.scss";
import {
  createInmueble,
  getDescriptionInmueble,
  getNombreInmueble,
  getPreentradaInmueble,
  getPostSalidaInmueble,
  getPoliciaIdInmueble,
} from "../../../../api/inmueble";
import { Column } from "@antv/g2plot";
import DesplegableMultiple from "../../../DesplegableMultiple";
import Boton_peticion_handler from "../../boton_peticion_handler/boton_peticion_handler";
import TimepickerLimpieza from "../../../TimepickerLimpieza";
import { Graph } from "@ant-design/charts";
import GraphBarrasApiladas from "../../../GraphBarrasApiladas";
import { basePathFrontend } from "../../../../api/config";
import UserTooltip from "./UserTooltip";
import Calendario from "../../../../pages/Admin/Calendario";
import Boton_peticion_handler_v2 from "../../boton_peticion_handler_v2/boton_peticion_handler_v2";

const { confirm } = ModalAntd;
const { TextArea } = Input;
export default function Listinquilinos(props) {
  const {
    setSelectedValues,
    usersActive,
    usersInactive,
    setReloadUsers,
    reloadUsers,
    selectedValues2,
    selectedDate
  } = props;

  
  const [viewUsersActives, setViewUsersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [peticionLoading, setpeticionLoading] = useState(false);

  const onChangeCheckbox = (checkedValues) => {
    setCheckedList(checkedValues);
    // Aquí puedes añadir la lógica para filtrar usuarios basado en checkedValues
  };

  const addUserModal = () => {
    setIsVisibleModal(true);

    setModalTitle("Creando nuevo usuario");
    setModalContent(
      <AddInquilinoForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };

  const cargarNuevosUsuarios = async () => {
    setpeticionLoading(true);
    const token = getAccessTokenApi();
  
    try {
      const response = await avaibookCargarInquilinos(token);
      //console.log(response);
    } catch (error) {
      //console.log("Error al traer los inquilinos: " + error);
      notification["error"]({
        message: error.toString(),
      });
    } finally {
      setpeticionLoading(false);
    }
  };

  const handleSelectChange = (values) => {
    setSelectedValues(values);
    setReloadUsers(true)
  };
  return (
    
    <div className="list-users">
      
      
      <Row gutter={24}>
        {/* <Col span={8}>
          <span>
            {viewUsersActives ? "Usuarios Activos: " : "Usuarios Inactivos: "}
          </span>
          <Switch
            defaultChecked
            onChange={() => setViewUsersActives(!viewUsersActives)}
          />
        </Col> */}
        {/* <Col span={24}>
        <Radio.Group onChange={onChangeRadio}>
          <Row>
            <Col span={6}>
              <Radio value="pendiente">Pendiente de valoración</Radio>
            </Col>
            <Col span={6}>
              <Radio value="entranSalenHoy">Usuarios que entran y salen hoy</Radio>
            </Col>
            <Col span={6}>
              <Radio value="activos">Todos los usuarios activos</Radio>
            </Col>
            <Col span={6}>
              <Radio value="inactivos">Usuarios inactivos</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Col> */}
    
        <Col span={8}>
          <DesplegableMultiple
            valores={["Inquilino", "Propietario", "Limpiador"]}
            defaultValue2={selectedValues2}
            onChange={handleSelectChange}
          />
          {/* <Button onClick={() =>}> Buscar</Button> */}
        </Col>

        {/* <Button
            type="primary"
            onClick={() => {
              //console.log("Hola");
              notification.open({
                message: "Nuevo inquilino creado",
                description: "",
                onClick: () => {
                  //console.log("Notification Clicked!");
                },
              });
            }}
          >
            Open the notification box
          </Button> */}
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button type="primary" onClick={addUserModal}>
            Nuevo inquilino
          </Button>
        </Col>

        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <Boton_peticion_handler
                    isLoading={peticionLoading}
                    cuerpo={<>Cargar nuevos inquilinos de Avaibook <NotificationOutlined /></>}
                    handler={cargarNuevosUsuarios}
          >
            
          </Boton_peticion_handler>
          
        </Col>
      </Row>
      <Row>
        <div style={{ height: 10 }}></div>
      </Row>

      {/* {viewUsersActives ? (
        <UsersActive
          usersActive={usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
          setReloadUsers={setReloadUsers}
          reloadUsers={reloadUsers}
        />
      ) : (
        <UsersInactive
          usersInactive={usersInactive}
          setReloadUsers={setReloadUsers}
        />
      )} */}


      <UsersActive
        usersActive={usersActive}
        setIsVisibleModal={setIsVisibleModal}
        setModalTitle={setModalTitle}
        setModalContent={setModalContent}
        setReloadUsers={setReloadUsers}
        reloadUsers={reloadUsers}
        selectedDate={selectedDate}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
      </div>
     
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadUsers,
    reloadUsers,
    selectedDate
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${user.lastname ? user.lastname : "..."
      }`
    );
    setModalContent(
      <EditInquilinoForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };
  const seeCalendar = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${user.lastname ? user.lastname : "..."
      }`
    );

    setModalContent();
  };

  return (
    <>
    <><b>REALOJAMIENTOS / INCIDENCIAS ESCRIBIR aqui:</b> <a href="https://docs.google.com/document/d/1QEc0TTWiguhtDKMuf1y4_-Zz7p1GR2PCsGh6MnCL1Cc/edit?tab=t.0">CLICK AQUI</a></>
    <Collapse>
     
    <Collapse.Panel header={`Numero de Inquilinos: ${usersActive.length}`} key="Inquilinos">
    
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersActive}
      renderItem={(user) => (
        <UserActive
        selectedDate={selectedDate}
          seeCalendar={seeCalendar}
          user={user}
          editUser={editUser}
          setReloadUsers={setReloadUsers}
          reloadUsers={reloadUsers}
        />
      )}
    />
      </Collapse.Panel>
   
    <Collapse.Panel header="Calendario Inquilinos" key="calendario">
        <Calendario></Calendario>
      </Collapse.Panel>
      </Collapse>
    </>
  );
}

function UserActive(props) {
  const { user, editUser, setReloadUsers, reloadUsers,selectedDate } = props;
  const [avatar, setAvatar] = useState(null);
  const [nombre_piso, setNombrePiso] = useState(null);
  const [policiaId, setpoliciaId] = useState(false);
  const [peticionLoading, setpeticionLoading] = useState(false);
  const [mensajeGenerado, setMensajeGenerado] = useState('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [selectedDate_user_active, setselectedDate_user_active] = useState(selectedDate ?? moment.now());
  const [observaciones, setObservaciones] = useState(user.observaciones || '');
  const handleObservacionesChange = (e) => {
    setObservaciones(e.target.value);
  };

  useEffect(()=>{setselectedDate_user_active(selectedDate);},[selectedDate])
  const handleObservacionesUpdate = async () => {
    try {
      const updatedUser = { ...user, observaciones: observaciones };
      const response = await updateInquilinoApi(getAccessTokenApi(), updatedUser, user._id);
      if (response.message) {
        notification.success({
          message: 'Observaciones actualizadas correctamente',
        });
        // Actualizar el estado para recargar los usuarios
        setReloadUsers(true);
      } else {
        notification.error({
          message: 'Error al actualizar las observaciones',
        });
      }
    } catch (error) {
      console.error('Error al actualizar las observaciones:', error);
      notification.error({
        message: 'Error al actualizar las observaciones',
      });
    }
  };
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };


  const desactivateUser = () => {
    const accesToken = getAccessTokenApi();
    user.active = false;
    //console.log("desactivateUser", user);
    updateInquilinoApi(accesToken, user, user._id)
      .then((response) => {
        notification["success"]({
          message: "Desactivado",
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: "Problema desactivando",
        });
        setReloadUsers(true);
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${user.nombre}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteInquilinoApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };
  async function abrirEnlace() {
    setpeticionLoading(true);
    let enlace =
      basePathFrontend + "/acceder/" +
      user.code +
      "%0d%0a(Válido desde el " +
      user.fecha_entrada +
      " hasta el " +
      user.fecha_salida +
      ")";
    const accessToken = getAccessTokenApi();
    await getDescriptionInmueble(
      user.inmueble,
      accessToken,
      user.idioma_mensaje ?? "ES"
    )
      .then((response) => {
        window.open(
          "https://api.whatsapp.com/send?phone=" +
          user.telefono +
          "&text=" +
          response.description
            .replace("@enlace_inquilino", enlace)
            .replace("@codigo_nuki", user.code_nuki ?? "")
            .replace("@nombre_inquilino", user.name ?? "")
            .replace("@codigo_huespedes", response.codigo_huespedes),
           
          "_blank"
        );
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    await postNewEstadoFlujo(accessToken, user._id)
      .then((response) => {
        //console.log(response);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    setReloadUsers(true);
    setpeticionLoading(false);
  }

  async function mensajeAirbnbBooking() {
    setpeticionLoading(true);
    const accessToken = getAccessTokenApi();
    try {
      const response = await getPreentradaInmueble(
        user.inmueble,
        accessToken,
        user.idioma_mensaje ?? "ES"
      );

      const mensaje = response.mensaje_pre_entrada
        .replace("@nombre_inquilino", user.name ?? "")
        .replace("@fecha_entrada", user.fecha_entrada ?? "")
        .replace("@fecha_salida", user.fecha_salida ?? "")
        .replace("@formulario_ids", basePathFrontend + "/id_upload/" + user.code ?? "");

      setMensajeGenerado(mensaje);
    } catch (err) {
      notification["error"]({
        message: err,
      });
    }
    setpeticionLoading(false);
    setReloadUsers(true);
  }

  useEffect(() => {
    mensajeAirbnbBooking();
  }, []);

  async function abrirEnlace_premensaje() {
    setpeticionLoading(true);
    const accessToken = getAccessTokenApi();
    await getPreentradaInmueble(
      user.inmueble,
      accessToken,
      user.idioma_mensaje ?? "ES"
    )
      .then((response) => {
        //console.log(response); 
        window.open(
          "https://api.whatsapp.com/send?phone=" +
          user.telefono +
          "&text=" +
          response.mensaje_pre_entrada
            .replace("@nombre_inquilino", user.name ?? "")
            .replace("@fecha_entrada", user.fecha_entrada ?? "")
            .replace("@fecha_salida", user.fecha_salida ?? "")
            .replace(
              "@formulario_ids",
              basePathFrontend + "/id_upload/" + user.code ?? ""
            ),
          "_blank"
        );
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    await postNewEstadoFlujo(accessToken, user._id)
      .then((response) => {
        //console.log(response);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    setReloadUsers(true);
    setpeticionLoading(false);
  }

  async function abrirEnlace_post_mensaje() {
    setpeticionLoading(true);
    const accessToken = getAccessTokenApi();

    await getPostSalidaInmueble(
      user.inmueble,
      accessToken,
      user.idioma_mensaje ?? "ES"
    )
      .then((response) => {
        //console.log(response);
        window.open(
          "https://api.whatsapp.com/send?phone=" +
          user.telefono +
          "&text=" +
          response.mensaje_post_salida
            .replace("@nombre_inquilino", user.name ?? "")
            .replace("@fecha_entrada", user.fecha_entrada ?? "")
            .replace("@fecha_salida", user.fecha_salida ?? ""),
          "_blank"
        );
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });

    await postNewEstadoFlujo(accessToken, user._id)
      .then((response) => {
        //console.log(response);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    setReloadUsers(true);
    setpeticionLoading(false);
  }

  useEffect(() => {
    const token = getAccessTokenApi();
    getNombreInmueble(user.inmueble, token).then((response) => {
      setNombrePiso(response.message);
    });
    getPoliciaIdInmueble(user.inmueble, token).then((response) => {
      //console.log("getPoliciaIdInmueble", response);
      setpoliciaId(response.message);
    });
  }, [reloadUsers, user.inmueble]);

  const { RangePicker } = DatePicker;

  const now = moment(); // obtiene la fecha actual
  let mystyle;
  let mystyle2;
  let arrow = 0;
  let arrow_salida_dia_siguiente = 0;
  if (moment(user.fecha_entrada, "YYYY-MM-DD").isSame(selectedDate_user_active, "day")) {
    mystyle = { color: "green" };
    arrow = 0;
  } else if (moment(user.fecha_salida, "YYYY-MM-DD").isSame(selectedDate_user_active, "day")) {
    mystyle = { color: "red", fontSize:"26px", margin:"10px" };

    arrow = 1;
  } else {
    mystyle = { color: "white" };
    arrow = 2;
  }
  if (
    moment()
      .add(1, "day")
      .isSame(moment(user.fecha_salida, "YYYY-MM-DD"), "day")
  ) {
    arrow_salida_dia_siguiente = 1;
    mystyle2 = { color: "black" };
  }
  const today = new Date();
  const todayFormat = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const fechaEntrada = new Date(user.fecha_entrada);
  const fechaSalida = new Date(user.fecha_salida);
  const dosDiasAntes = new Date();
  dosDiasAntes.setDate(today.getDate() + 2);
  const tomorrrow = new Date();
  tomorrrow.setDate(today.getDate() + 1);
  const tresDiasAntes = new Date();
  tresDiasAntes.setDate(today.getDate() + 3);

  const isFechaEntradaAnterior = fechaEntrada <= dosDiasAntes;
  const isFechaEntradaPosterior = fechaEntrada <= today;
  const simananasevan = fechaSalida <= tomorrrow;
  // //console.log("MAÑNAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", user.name, tomorrrow);
  // //console.log("MAÑNooooooooooooooooo", fechaSalida);

  const codicion_premensaje_aviso_tres_dias_antes =
    (user.estado_flujo ?? "NO_CONTACTADO") === "NO_CONTACTADO" &&
    fechaEntrada <= tresDiasAntes;
  const codicion_instrucciones_aviso_dos_dias_antes =
    (user.estado_flujo ?? "NO_CONTACTADO") === "PREMENSAJE_ENVIADO" &&
    fechaEntrada <= dosDiasAntes;
  const codicion_instrucciones_aviso_un_dias_antes_de_marchar =
    (user.estado_flujo ?? "NO_CONTACTADO") === "INSTRUCCIONES_ENVIADAS" &&
    simananasevan;
  const condiciones =
    codicion_premensaje_aviso_tres_dias_antes ||
    codicion_instrucciones_aviso_dos_dias_antes ||
    codicion_instrucciones_aviso_un_dias_antes_de_marchar;
  // //console.log(now.format('YYYY-MM-DD')); // muestra la fecha actual en formato YYYY-MM-DD

  const onChange = (time, timeString) => {
    //console.log(time, timeString);
  };

  const renderDateIcon = (user) => {
    if (user.fecha_entrada === todayFormat) {
      return <ArrowLeftOutlined style={mystyle}/>;
    } else if (user.fecha_salida === todayFormat) {
      return <ArrowRightOutlined style={mystyle}/>;
    } else if (user.fecha_salida === tomorrow) {
      return <RollbackOutlined style={mystyle}/>;
    } else {
      return <></>; // No icon
    }
  };

  const calcularEdadMedia = (user) => {
    const hoy = new Date();
    const edades = user.id_de_los_inquilinos
      .filter(inquilino => inquilino.fechaNacimiento) // Filtra los inquilinos con fecha de nacimiento no vacía
      .map(inquilino => {
        // Separar el día, mes y año de la fecha de nacimiento
        const partesFecha = inquilino.fechaNacimiento.split('/');
        const dia = parseInt(partesFecha[0], 10);
        const mes = parseInt(partesFecha[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
        const año = parseInt(partesFecha[2], 10);
  
        // Crear un nuevo objeto Date con el año, mes y día
        const fechaNacimiento = new Date(año, mes, dia);
  
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
        const m = hoy.getMonth() - fechaNacimiento.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
          edad--;
        }
        return edad;
      });
  
    if (edades.length === 0) return null; // Si no hay edades válidas, devuelve null
  
    return edades.reduce((a, b) => a + b, 0) / edades.length;
  };

  const edadMedia = calcularEdadMedia(user);

  const obtenerIconoEdad = (edadMedia) => {
    if (edadMedia >= 0 && edadMedia < 20) return <AlertOutlined />;
    if (edadMedia >= 20 && edadMedia < 25) return <BellOutlined />;
    if (edadMedia >= 25 && edadMedia < 35) return <ExclamationOutlined/> ;
    return <CheckOutlined /> ;
  };

  const iconoEdad = obtenerIconoEdad(edadMedia);

  return (
    <div className="card-container" >
      <div className="flex-row">
        <div className="flex-column">
          <div className="flex-row">
              <UserTooltip renderDateIcon={renderDateIcon} 
                user={user} 
                editUser={editUser} 
                desactivateUser={desactivateUser} 
                basePathFrontend={basePathFrontend} 
                iconoEdad={iconoEdad} 
                setReloadUsers={setReloadUsers}
                reloadUsers={reloadUsers} />
          </div>
          <Boton_peticion_handler_v2 
              cuerpo={"Whatsapp"} 
              style={{backgroundColor:"green", borderColor:"green", width:"auto"}}
              handler={() => { 
                window.open(
                  "https://api.whatsapp.com/send?phone=" + user.telefono + "&text=.", 
                  "_blank"
                ); 
              }} 
            />
          <b><p>{nombre_piso || 'Nombre de la Propiedad'}</p></b>
          <p>{user.telefono ? `telefono: ${user.telefono}` : 'SIN NUMERO DE TLF'} </p>
          <p>Entrada: {user.fecha_entrada}  </p>
          <p>Salida: {user.fecha_salida} </p>
          <p>Edad media inquilinos: {Number(parseFloat(edadMedia)).toFixed(2)}</p>
          <p>{user.email ? `Email: ${user.email}` : ''} </p>
          {/* <button onClick={toggleAccordion} className="accordion-button">
            Datos de la reserva
          </button> */}
         
          
         {user?.observaciones?.length>1 ? 
                  

                    <div>
                    <div>
                      <p>Observaciones:</p>
                      <Input.TextArea
                        rows={5}
                        placeholder="Comentario sobre la reserva"
                        maxLength={40}
                        value={observaciones}
                        onChange={handleObservacionesChange}
                      />
                      <Button  type="primary" onClick={handleObservacionesUpdate}>Guardar</Button>

                      
                      <Divider type="horizontal"></Divider>
                    </div>
                    </div>:   <></> }
            <div className="accordion-container">
            <Collapse>
               <Collapse.Panel header="Datos reserva" key="Datos reserva">
              {isAccordionOpen && (
                <div className="accordion-content">
                <div>
                  <p>Plataforma: {user.plataforma || 'Nombre de la Plataforma'}</p>
                  <p>Precio: {user.pago || 'Precio'} €</p>
                  {/* <p>Numero de personas: {user.numero_personas} </p> */}
                
                  <p>{user.id_avaibook ? `ID avaibook: ${user.id_avaibook}` : ''} </p>



                  <div className="vertical-divider"></div>
                  <Divider>Información operativa</Divider>
                  {user?.observaciones?.length>1 ? 
                    <></> :  

                    <div>
                    <div>
                      <p>Observaciones:</p>
                      <Input.TextArea
                        rows={5}
                        placeholder="Comentario sobre la reserva"
                        maxLength={40}
                        value={observaciones}
                        onChange={handleObservacionesChange}
                      />
                      <Button onClick={handleObservacionesUpdate}>Guardar</Button>
                     
                    </div>
                    
                    </div>
                    }
                  <div className="flex-column">
                    
                    <div className="fechas">
                      Hora de entrada:
                      <TimepickerLimpieza hora_actual={user.hora_entrada} onOkHandler={async (token, my_hora) => {
                        //console.log("TIMEPICKER PRUEBA", my_hora)
                        const resp = await updateInquilinoApi(token, { hora_entrada: my_hora }, user._id);
                        //console.log((resp));
                        if (resp.message) {
                          notification["success"]({
                            message: "Actualizado",
                          });

                        } else {
                          notification["error"]({
                            message: "Problema al actualizar",
                          });
                        }
                      }}>
                      </TimepickerLimpieza>
                    </div>
                    <div className="fechas">
                      Hora de salida:
                      <TimepickerLimpieza hora_actual={user.hora_salida} onOkHandler={async (token, my_hora) => {
                        const resp = await updateInquilinoApi(token, { hora_salida: my_hora }, user._id);
                        //console.log((resp));
                        if (resp.message) {
                          notification["success"]({
                            message: "Actualizado",
                          });

                        } else {
                          notification["error"]({
                            message: "Problema al actualizar",
                          });
                        }
                      }}>
                      </TimepickerLimpieza>
                    </div>

                  </div>
                  
                </div>
                </div>
              )}
               </Collapse.Panel>
              
              <div className="vertical-divider"></div>
              <Collapse.Panel 
                  header={policiaId === true ? (`DNI- ${Number(user.numero_ids_enviados ?? user.numero_personas) === 0 
                    ? "Hechos!" 
                    : `DNI, ya enviados: ${user.numero_personas - (user.numero_ids_enviados ?? user.numero_personas)}/${user.numero_personas}`}`):"falta_policia_id"} 
                  key="DNI" 
                  style={policiaId === true ?
                    {
                    backgroundColor: 
                      Number(user.numero_ids_enviados ?? user.numero_personas) === 0
                        ? "green"
                        : isFechaEntradaPosterior
                          ? "red"
                          : isFechaEntradaAnterior
                            ? "orange"
                            : "blue",
                    borderColor: 
                      Number(user.numero_ids_enviados ?? user.numero_personas) === 0
                        ? "green"
                        : isFechaEntradaPosterior
                          ? "red"
                          : isFechaEntradaAnterior
                            ? "orange"
                            : "blue",
                  } : {}
                }
                >
                  <div className="message-button">
                    <>
                      {policiaId === true ? (
                        <Button
                          onClick={() => {
                            window.open(
                              basePathFrontend + "/id_upload/" + user.code,
                              "_blank"
                            );
                          }}
                          type="primary"
                          label="Enviar DNIs"
                          style={{
                            backgroundColor:
                              Number(user.numero_ids_enviados ?? user.numero_personas) ===
                                0
                                ? "green"
                                : isFechaEntradaPosterior
                                  ? "red"
                                  : isFechaEntradaAnterior
                                    ? "orange"
                                    : "blue",

                            borderColor:
                              Number(user.numero_ids_enviados ?? user.numero_personas) ===
                                0
                                ? "green"
                                : isFechaEntradaPosterior
                                  ? "red"
                                  : isFechaEntradaAnterior
                                    ? "orange"
                                    : "blue",
                          }}
                        >
                          {Number(user.numero_ids_enviados ?? user.numero_personas) === 0
                            ? "Hechos!"
                            : `DNI, ya enviados: ${user.numero_personas -
                            (user.numero_ids_enviados ?? user.numero_personas)
                            }/${user.numero_personas} `}
                        </Button>
                      ) : (
                        <span>Falta policia_id</span>
                      )}{" "}
                    </>
                    <UserInquilinosDni user={user}/>
                    {user.observaciones ?  <div>
          <div>
            <p>Observaciones:</p>
            <Input.TextArea
              rows={5}
              placeholder="Comentario sobre la reserva"
              maxLength={40}
              value={observaciones}
              onChange={handleObservacionesChange}
            />
            <Button  type="primary" onClick={handleObservacionesUpdate}>Guardar</Button>
          </div>
        </div>: <></>}
                    <CopyToClipboard
                      text={basePathFrontend + "/acceder/" + user.code}
                      BUTTON_TEXT={" abrir puerta"}
                    />
                    <CopyToClipboard
                      text={mensajeGenerado}
                      BUTTON_TEXT={"Mensaje predefindo"}
                    />
                      <CopyToClipboard_v2
                      handler={async ()=>
                        {
                            let enlace =
                            basePathFrontend + "/acceder/" +
                            user.code +
                            " %0d%0a(Válido desde el " +
                            user.fecha_entrada +
                            " hasta el " +
                            user.fecha_salida +
                            ")";
                            const accessToken = getAccessTokenApi();
                            let response = await getDescriptionInmueble(
                              user.inmueble,
                              accessToken,
                              user.idioma_mensaje ?? "ES"
                            )
                            const formattedResponse = response.description
                                  .replace("@enlace_inquilino", enlace)
                                  .replace("@codigo_nuki", user.code_nuki ?? "")
                                  .replace("@nombre_inquilino", user.name ?? "")
                                  .replace("@codigo_huespedes", response.codigo_huespedes)
                                  .replace(/%0d%0a/g, "\n");

                                navigator.clipboard.writeText(formattedResponse);

                               
                        }}

                      BUTTON_TEXT={"Instrucciones entrada"}
                    />
                 
                
            </div>
               </Collapse.Panel>
               </Collapse>
                 <Divider></Divider>
                  <div className="boton-flujo">
                    <div className="boton-flujo" >
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Boton_peticion_handler
                        isLoading={peticionLoading}
                        cuerpo={<NotificationOutlined />}
                        handler={abrirEnlace_premensaje}
                        style={{
                          backgroundColor:
                            (user.estado_flujo ?? "NO_CONTACTADO") === "NO_CONTACTADO"
                              ? "blue"
                              : "grey",
                          borderColor:
                            (user.estado_flujo ?? "NO_CONTACTADO") === "NO_CONTACTADO"
                              ? "blue"
                              : "grey",
                        }}
                      />
                    </div>
                    <div className="boton-flujo">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Boton_peticion_handler
                        className="boton-flujo"
                        isLoading={peticionLoading}
                        cuerpo={<CommentOutlined />}
                        handler={abrirEnlace}
                        style={{
                          backgroundColor:
                            (user.estado_flujo ?? "NO_CONTACTADO") === "PREMENSAJE_ENVIADO"
                              ? "green"
                              : "grey",
                          borderColor:
                            (user.estado_flujo ?? "NO_CONTACTADO") === "PREMENSAJE_ENVIADO"
                              ? "green"
                              : "grey",
                        }}
                      />
                    </div>
                    <div className="boton-flujo">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <Boton_peticion_handler
                        isLoading={peticionLoading}
                        cuerpo={<ArrowRightOutlined />}
                        handler={abrirEnlace_post_mensaje}
                        style={{
                          backgroundColor:
                            (user.estado_flujo ?? "NO_CONTACTADO") ===
                              "INSTRUCCIONES_ENVIADAS"
                              ? "orange"
                              : "grey",
                          borderColor:
                            (user.estado_flujo ?? "NO_CONTACTADO") ===
                              "INSTRUCCIONES_ENVIADAS"
                              ? "orange"
                              : "grey",
                        }}
                      />
                    </div>
                  </div>
              </div>
         
          
          <div className="vertical-divider"></div>
         
        </div >
      </div>
    </div>
  );
}

function UsersInactive(props) {
  const { usersInactive, setReloadUsers } = props;

  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersInactive}
      renderItem={(user) => (
        <UserInactive user={user} setReloadUsers={setReloadUsers} />
      )}
    />
  );
}

function UserInactive(props) {
  const { user, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const activateUser = () => {
    const accesToken = getAccessTokenApi();

    user.active = true;
    //console.log("desactivateUser", user);
    updateInquilinoApi(accesToken, user, user._id)
      .then((response) => {
        notification["success"]({
          message: "Inquilino activado",
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: "Error activando inquilino",
        });
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${user.name}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteInquilinoApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={activateUser}>
          <CheckOutlined />
        </Button>,
        <Button type="danger" onClick={showDeleteConfirm}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
        title={`
                    ${user.name ? user.name : "..."} 
                    ${user.lastname ? user.lastname : "..."}
                `}
        description={user.email}
      />
    </List.Item>
  );
}


