import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  getInquilinosApi,
  queryInquilinosApi,
  Keyrock,
} from "../../../../api/inquilino";
import {
  Switch,
  List,
  Avatar,
  Button,
  Modal as ModalAntd,
  notification,
  Calendar,
  DatePicker,
  Space,
  Icon,
  Divider,
  Row,
  Col,
  Card,
  Typography,
  Select,  // Importamos Select para crear un selector de series
} from "antd";
import { EyeOutlined, UserOutlined, FileTextOutlined } from "@ant-design/icons";



import ListInquilinos from "../../../../components/Admin/Users/Listinquilinos";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";

import "./Facturas.scss";
import {
  getFacturas,
  getFacturasProforma,
  getSeries,
  getSeriesInmueble,  // Función para obtener series
} from "../../../../api/series";
import FacturasProductos from "../../../../components/FacturasProductos/FacturasProductos";
import { getInmuebleDatosFacturacion, obtenerListaInmuebles } from "../../../../api/inmueble";
import { enviarCorreoUsuario } from "../../../../api/user";

const { Text, Title } = Typography;


const { Option } = Select;  // Definimos Select para usarlo en el código

export default function Facturas_generadas_plataforma(props) {
  const { factura_proforma } = props;
  const [usersActive, setUsersActive] = useState([]); // Todas las facturas
  const [filteredFacturas, setFilteredFacturas] = useState([]); // Facturas filtradas por serie
  const [series, setSeries] = useState([]); // Series disponibles
  const [selectedSerie, setSelectedSerie] = useState(null); // Serie seleccionada
  const [reloadUsers, setReloadUsers] = useState(false);
  const [sumaTotal, setSumaTotal] = useState(0); // Estado para almacenar la suma total

  const token = getAccessTokenApi();
  useEffect(() => {
    const calcularSumaTotal = () => {
      const suma = filteredFacturas.reduce((acc, factura) => {
        const totalFactura = factura.conceptos_facturar.reduce((sum, concepto) => {
          const totalConcepto = parseFloat(concepto.total);
          return !isNaN(totalConcepto) ? sum + totalConcepto : sum; // Sólo sumar si no es NaN
        }, 0);
        return acc + totalFactura;
      }, 0);
    
      setSumaTotal(suma.toFixed(2)); // Establecemos la suma total con dos decimales
    };
    
  
    calcularSumaTotal(); // Llamamos a la función de cálculo de suma
  }, [filteredFacturas]); // Ejecutar cada vez que cambien las facturas filtradas
  
  useEffect(() => {}, [usersActive]); // Solo se refresca si cambian estos
  useEffect(() => {
    
    const accesToken = getAccessTokenApi();
    obtenerListaInmuebles(accesToken).then((response) => {
      //console.log(response.Pisos[0].id);
      var piso_obtener_facturacion = [];

      let pisos_ordenados = response.Pisos.sort((a, b) => a.nombre.localeCompare(b.nombre));

      for (let i = 0; i < response.Pisos.length; i++) {
        piso_obtener_facturacion.push(
          <Option value={pisos_ordenados[i].id}>
            {pisos_ordenados[i].nombre}
          </Option>
        );
      }
      set_piso_obtener_data_facturacion(piso_obtener_facturacion);
    });
 

    setReloadUsers(false);
  }, [reloadUsers]);
  // Cargar las series disponibles
  useEffect(() => {
    getSeries(token).then((response) => {
      setSeries(response.series);  // Cargar las series
    });
  }, [token]);

  // Cargar las facturas y filtrar según la serie seleccionada
  useEffect(() => {
    if (factura_proforma) {
      getFacturasProforma(token).then((response) => {
        setUsersActive(response.facturas);
        filtrarFacturas(response.facturas, selectedSerie); // Filtrar facturas al cargar
      });
    } else {
      getFacturas(token).then((response) => {
        setUsersActive(response.facturas);
        filtrarFacturas(response.facturas, selectedSerie); // Filtrar facturas al cargar
      });
    }
    setReloadUsers(false);
  }, [token, reloadUsers, selectedSerie]); // Refrescar si cambia la serie seleccionada

  // Función para filtrar las facturas según la serie seleccionada
  // const filtrarFacturas = (facturas, serie) => {
  //   if (serie) {
  //     const facturasFiltradas = facturas.filter((factura) => factura.serie_facturacion === serie);
  //     setFilteredFacturas(facturasFiltradas);
  //   } else {
  //     setFilteredFacturas(facturas);  // Si no hay serie seleccionada, mostramos todas las facturas
  //   }
  // };
  const filtrarFacturas = (facturas, serie) => {
    let facturasFiltradas = facturas;
    if(serie.includes("TODAS")){
          // Ordenar por número de factura de forma ascendente
      facturasFiltradas.sort((a, b) => parseInt(b.numero_factura) - parseInt(a.numero_factura));

      // Actualizar el estado con las facturas filtradas y ordenadas
      setFilteredFacturas(facturasFiltradas);
      return;
    }
    // Filtrar por serie si está seleccionada
    if (serie) {
      facturasFiltradas = facturas.filter((factura) => serie.includes(factura.serie_facturacion) );
    }

    // Ordenar por número de factura de forma ascendente
    facturasFiltradas.sort((a, b) => parseInt(b.numero_factura) - parseInt(a.numero_factura));

    // Actualizar el estado con las facturas filtradas y ordenadas
    setFilteredFacturas(facturasFiltradas);
  };
  // Función para obtener el nombre de la serie
  const obtenerNombreSerie = (serieId) => {
    const serie = series.find((serie) => serie._id === serieId);
    return serie ? serie.nombre_serie : "Serie no encontrada";
  };
  const obtenerLomoSerie = (serieId) => {
    console.log("obtenerLomoSerie", serieId)
    const serie = series.find((serie) => serie._id === serieId);
    return serie ? serie.nombre_lomo : "";
  };


  // Componente que representa una factura activa
  function SeriesActive(props) {
    const [factura_visible, setfactura_visible] = useState(false);
    useEffect(() => {}, [factura_visible]);

    const { item } = props;
    return (

      <Card className="factura-card" bordered={true} style={{ marginBottom: "20px", borderRadius: "10px" }}>
      
      <List.Item
        actions={[
          <Button
            type="primary"
            shape="round"
            icon={<EyeOutlined />}
            onClick={() => {
              setfactura_visible(!factura_visible);
            }}
            style={{ backgroundColor: "#4CAF50", borderColor: "#4CAF50", color: "white" }}
          >
              Ver detalles
          </Button>,
          //   <Button
          //     onClick={ async ()=>{
          //       const resp = await enviarCorreoUsuario(record.id, "Limpiezas disponibles mañana:"+(record.pisosAsignados??"XX")+"!", "Entra en la platadorma "+basePathFrontend+" para verlo" + record.nombres_pisos);
          //       if(resp.message){
          //         notification["success"]({
          //           message: resp.message
          //         });
          //       }else{
          //         notification["error"]({
          //           message: resp.error
          //         });
          //       }
          //     }}
          //   >

          // </Button>

        ]}
        style={{
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          padding: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col span={12}>
            <Title level={5} style={{ marginBottom: 4, display: "flex", alignItems: "center" }}>
              <FileTextOutlined style={{ marginRight: "8px", color: "#1890ff", fontSize: "20px" }} />
              {obtenerNombreSerie(item.serie_facturacion)} {item.numero_factura}
            </Title>
            <Text type="secondary" style={{ fontSize: "14px" }}>
              Fecha de emisión: {item.fecha_emision_factura}
            </Text>
          </Col>
        </Row>

        <Row style={{ width: "100%", marginTop: "12px" }}>
          <Col span={12} style={{ paddingRight: "16px" }}>
            <Text strong style={{ color: "#333", fontSize: "14px" }}>
              Emisor: {item.data_emisor.nombre}
            </Text>
            <br />
            <Text style={{ color: "#666", fontSize: "13px" }}>
              Dirección: {item.data_emisor.direccion_emisor}
            </Text>
            <br />
            <Text style={{ color: "#666", fontSize: "13px" }}>
              NIF: {item.data_emisor.numero_identificacion_fiscal}
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text strong style={{ color: "#333", fontSize: "14px" }}>
              Receptor: {item.data_receptor.nombre}
            </Text>
            <br />
            <Text style={{ color: "#666", fontSize: "13px" }}>
              Dirección: {item.data_receptor.direccion_emisor}
            </Text>
            <br />
            <Text style={{ color: "#666", fontSize: "13px" }}>
              NIF: {item.data_receptor.numero_identificacion_fiscal}
            </Text>
            Retencion: {item.retencion}
          </Col>

        </Row>

        <Row style={{ width: "100%", marginTop: "16px", borderTop: "1px solid #e0e0e0", paddingTop: "10px" }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Title level={5} style={{ color: "#4CAF50", fontWeight: "bold", marginBottom: 0 }}>
              Total factura: {item.conceptos_facturar.reduce((acc, concepto) => acc + parseFloat(Number(concepto.total)), 0).toFixed(2)} €
            </Title>
          </Col>
        </Row>
      </List.Item>


    
    {factura_visible ? (
      <div style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
        <FacturasProductos

          retencion={item.retencion}
          serie_nombre =  {obtenerNombreSerie(item.serie_facturacion)}
          numero_factura={item.numero_factura ?? ""}
          nombre_lomo={
            obtenerLomoSerie(item.serie_facturacion ) 
          }
          proforma={false}
          nombre_emisor={item.data_emisor.nombre ?? ""}
          direccionEmisor={item.data_emisor.direccion_emisor ?? ""}
          nifEmisor={item.data_emisor.numero_identificacion_fiscal ?? ""}
          nombreReceptor={item.data_receptor.nombre ?? ""}
          direccionReceptor={item.data_receptor.direccion_emisor ?? ""}
          nifReceptor={item.data_receptor.numero_identificacion_fiscal ?? ""}
          elementosFactura={item.conceptos_facturar ?? ""}
          fecha_creacion={item.fecha_emision_factura ?? ""}
          datos_facturacion_administrador={item.datosFacturacionAdministrador ?? []}
          datos_mes={item.conceptos_facturar ?? []}
          inquilinos={usersActive ?? []}
          datos_facturacion={item.inmueble_facturacion ?? {}}

        />
      </div>
    ) : null}
  </Card>
    );
  }

  // Manejo del cambio en el selector de serie
  const handleSerieChange = (value) => {
    setSelectedSerie(value);
    filtrarFacturas(usersActive, value);  // Filtrar las facturas al cambiar la serie
  };
  const [piso_obtener_data_facturacion, set_piso_obtener_data_facturacion] = useState([]);
  
  const [inmuebleSelected, setInmuebleSelected] = useState("");
  const [numero_factura, setnumero_factura] = useState("");
  const [nombre_serie, setnombre_serie] = useState("");
  const [serie_id, setserie_id] = useState("" );
  
  const [map_series, setmap_series] = useState({});
  const [series_elementos_select, setseries_elementos_select] = useState([]);

  const [nombreEmisor, setNombreEmisor] = useState("");
  const [nifEmisor, setNifEmisor] = useState("");
  const [direccionEmisor, setDireccionEmisor] = useState("");

  useEffect(() => {
    getSeriesInmueble(getAccessTokenApi(), inmuebleSelected).then((response) => {
      console.log("RESPONSE getSeriesInmueble", response);
  
      var series_elementos = [];
      var series_elementos_aux = response?.message ?? {};
  
      // Comprobamos si `response.message` existe antes de usarlo
      if (series_elementos_aux && typeof series_elementos_aux === "object") {
        Object.keys(series_elementos_aux).forEach((element, index) => {
          console.log("CAMBIOS", element);
          // Nos aseguramos de que el valor y el texto dentro del Option sean válidos para renderizar
         
          let value = series_elementos_aux[element];
          if(!value){
            return;
          }
          let label = element;
  
          // Si `value` es un objeto, convierte a una cadena válida o usa una propiedad adecuada
          if (typeof value === "object") {
            value = JSON.stringify(value); // O puedes elegir una propiedad específica si es más útil
          }
  
          series_elementos.push(
            <Option key={index} value={value}>
              {label}
            </Option>
          );
        });
      }
  
      // Establecer el estado con las series obtenidas
      setmap_series(series_elementos_aux);
      setseries_elementos_select(series_elementos);
    });
  }, [inmuebleSelected]);

  return (
    <>
      {/* Selector de series */}
      Selecciona Piso y tipo de serie
      <Select
       style={{ minWidth: 200 }} // Ancho mínimo
                  value={inmuebleSelected} // <== Añadir esta línea
                  onChange={async(e) => {
                    setInmuebleSelected(e)
                    let response = await getInmuebleDatosFacturacion(getAccessTokenApi(), e);
                    if(!response.message){
                      notification["error"]({
                        message: "Error"
                      });
                    }else{

                      setNifEmisor(response?.datos?.id_facturacion)
                      setDireccionEmisor(response?.datos?.direccion_facturacion)
                      setNombreEmisor(response?.datos?.nombre_facturacion)

                    }
                  }}
                  placeholder={"Selecciona un inmueble"}
                >
              {piso_obtener_data_facturacion}
            </Select>
      <Select
       style={{ minWidth: 200 }} // Ancho mínimo
              mode="multiple"
              onChange={(e) => {
                try {
                  handleSerieChange(e)
                  // Verificamos que `series[e]` no sea `undefined` o `null`
                  const selectedSeries = series?.[e];

                  if (selectedSeries) {
                    // Si existe, establecemos los valores
                    setnumero_factura(selectedSeries.numero_factura ?? "N/A"); // Si no tiene numero_factura, "N/A" como fallback
                    setserie_id(e);
                    setnombre_serie(selectedSeries.nombre ?? "Nombre desconocido"); // Valor por defecto si no hay nombre
                  } else {
                    console.warn("Serie seleccionada no encontrada:", e);
                    // Opcionalmente puedes realizar alguna acción, como mostrar un mensaje o limpiar los campos.
                  }
                } catch (error) {
                  console.error("Error al seleccionar la serie:", error);
                  // Aquí podrías manejar el error, mostrando un mensaje de alerta o logueando el problema.
                }
              }}
              placeholder={"Selecciona una serie"}
            >
              {series_elementos_select}
            </Select>

            <br></br>
            Selecciona series:
      <Select
      
        mode="multiple"
        placeholder="Selecciona una serie"
       
        style={{ minWidth: 200, marginBottom: 20 }}
        onChange={handleSerieChange}
        allowClear
      >
        
        <Option key="TODAS" value="TODAS">
           - SELECCIONAR TODAS -
         </Option>
        {series.map((serie) => (
          <Option key={serie._id} value={serie._id}>
            {serie.nombre_serie}
          </Option>
        ))}
      </Select>
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Title level={4}>
          Suma total de las facturas seleccionadas: {sumaTotal} €
        </Title>
      </div>
      {/* Listado de facturas filtradas */}
      <List
        itemLayout="horizontal"
        dataSource={filteredFacturas} // Mostramos las facturas filtradas
        renderItem={(item, index) => <SeriesActive item={item}></SeriesActive>}
      />
    </>
  );
}
