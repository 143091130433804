import React, { useState, useEffect } from "react";
import moment from "moment";
import { obtenerListaInmuebles } from "../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../api/auth";
import TablePlot from "../../../../components/Admin/Graphs/Table";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import FacturasProductos from "../../../../components/FacturasProductos/FacturasProductos";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
  Tabs,
  Card,
} from "antd";
import Modal from "../../../../components/Modal";
import { FilePdfOutlined } from "@ant-design/icons";
import {
  getFacturacion,
  getFacturacionTotal,
  getFacturacionDatos,
} from "../../../../api/facturacion";
import Listinquilinosfacturacion from "../../../../components/Admin/Users/Listinquilinosfacturacion";
import DemoPie from "../../../../components/Admin/Graphs/Pie";

import CrearFacturas from "../CrearFacturas";
import Factura_propietario_subir from "../Factura_propietario_subir";
import Facturas from "../Facturas/Facturas";
import Facturas_generadas_plataforma from "../Facturas/Facturas_generadas_plataforma";
import LectorDni from "../../../../components/LectorDni";
import { useReactTable } from "@tanstack/react-table";
import { createSerie, getSeries } from "../../../../api/series";
import jwtDecode from 'jwt-decode';

import Series from "../Series";
const { Option } = Select;

export default function Administrar_facturas() {
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const token = getAccessTokenApi();
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role); // Asegúrate de que el rol está incluido en el token
    }
  }, []);
  return (
    <>
      <Tabs defaultActiveKey="5" onChange={() => { }}>
      {(userRole === 'Administrador' || userRole === 'Gestor') && (
          <Tabs.TabPane tab="Crear factura" key="1">
            <CrearFacturas />
          </Tabs.TabPane>
        )}
        {(userRole === 'Administrador' || userRole === 'Gestor') && (
          <Tabs.TabPane tab="Series" key="5">
            <Series />
          </Tabs.TabPane>
        )}

        {(userRole === 'Administrador' || userRole === 'Propietario' || userRole === 'Gestor') && (
          <Tabs.TabPane tab="Facturas" key="2">
            <Card>
              Subir factura a propietario
            <Factura_propietario_subir></Factura_propietario_subir>
            </Card>
            <Divider></Divider>
            <Card>
              Ver facturas emitidas a propietarios
              <Facturas factura_proforma={false} />
            </Card>
            
          </Tabs.TabPane>
        )}
        {/* {(userRole === 'Administrador'|| userRole === 'Gestor') && (
          <Tabs.TabPane tab="Proformas" key="3">
            <Facturas_generadas_plataforma factura_proforma={true} />
            
          </Tabs.TabPane>
        )} */}
        {(userRole === 'Administrador' || userRole === 'Gestor') && (
          <Tabs.TabPane tab="Facturas Generadas Plataforma" key="4">
            <Facturas_generadas_plataforma factura_proforma={false} />
          </Tabs.TabPane>
        )}
       
        {/* {(userRole === 'Administrador' || userRole === 'Gestor') && (
          <Tabs.TabPane tab="Subir Factura Propietario" key="6">
            <Factura_propietario_subir></Factura_propietario_subir>
          </Tabs.TabPane>
        )} */}
        {/* <Tabs.TabPane tab="Alta piso" key="5">
          <Alta_piso></Alta_piso>
        </Tabs.TabPane> */}
      </Tabs>
    </>
  );
}
