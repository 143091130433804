import React from "react";
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
import { Col, Row } from "antd";

export default function GenerarRemesaNorma34({ datos_facturacion_por_piso, mes, year }) {

  // Mapeo de datos de facturación con formato necesario
  const datos = datos_facturacion_por_piso.map((propietario) =>
    propietario.tipo_gestion === "Gestion_bruta"
      ? {
          'Cuenta de Abono': String(propietario.iban ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Otro Tipo de Cuenta': '', 
          'Código Swift (BIC)': String(propietario.bic ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Nombre Beneficiario': String(propietario.nombre_facturacion ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Domicilio': String(propietario.direccion_facturacion ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Código Postal / Localidad': String(propietario.codigoPostal ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Provincia': String(propietario.provincia ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'País': String(propietario.pais ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Referencia Beneficiario': String(propietario.id_facturacion ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Gastos': String(propietario.gastos ?? '').replace(/,/g, "").replace(/\s+/g, ""),
          'Concepto': `Abono renta ${propietario.nombre} ${mes + 1}/${year}`.replace(/,/g, ""),
          'Importe': String(Number(propietario.ingreso_propietario).toFixed(2) ?? '').replace(/,/g, "").replace(/\s+/g, ""),
        }
      : null
  ).filter(Boolean); // Eliminar los null del array
  
  
  

  // Función para copiar un registro al portapapeles
  const convertirACSV = (datos) => {
    const encabezados = Object.keys(datos[0]).join(","); // Obtener los encabezados de las columnas
    const filas = datos.map(fila =>
      Object.values(fila).join(",")
    ); // Convertir cada fila de datos en una cadena separada por comas
    return [encabezados, ...filas].join("\n"); // Unir los encabezados con las filas usando saltos de línea
  };

  // Función para copiar CSV al portapapeles
  const copiarAlPortapapeles = (datos) => {
    const csv = convertirACSV(datos); // Convertir datos a formato CSV

    navigator.clipboard.writeText(csv)
      .then(() => {
        console.log('Datos en formato CSV copiados al portapapeles');
      })
      .catch(() => {
        console.error('Error al copiar los datos');
      });
  };
  // Función para generar el archivo Excel
  const generarExcel = (datos) => {
    const ws = XLSX.utils.json_to_sheet(datos);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Remesa");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "remesa.xlsx");
  };

  return (
    <Row>
      <Col>
        Remesas: 
      </Col>
      <Col>
        <button onClick={() => generarExcel(datos)}>Descargar Remesas</button>
      </Col>
      <Col>
        <button onClick={() => copiarAlPortapapeles(datos)}>Copiar Remesas</button>
      </Col>
    </Row>
  );
}
