import { basePath, apiVersion } from "./config";

export async function createSerie(
  accessToken,
  nombre_serie,
  numero_serie_global,
  nombre_lomo
) {
  const url = `${basePath}/${apiVersion}/serie/create`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      nombre_serie: nombre_serie,
      numero_serie_global: numero_serie_global,
      nombre_lomo: nombre_lomo,
    }),
  };

  return fetch(url, params)
    .then(async (response) => {
      return await response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getSeries(accessToken) {
  const url = `${basePath}/${apiVersion}/series`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getSeriesInmueble(accessToken, inmueble) {
  const url = `${basePath}/${apiVersion}/series-inmueble/${inmueble}`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function deleteSerie(accessToken,id) {
  const url = `${basePath}/${apiVersion}/serie/${id}`;
  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function updateSerie(accessToken, id, nombre_serie, numero_serie_global, nombre_lomo) {
  const url = `${basePath}/${apiVersion}/serie/${id}`;
  
  // Estructura de los datos a enviar
  const body = {
    nombre_serie,
    numero_serie_global,
    nombre_lomo
  };

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`, // Añadido Bearer para mejor formato
    },
    body: JSON.stringify(body) // Convertir los datos a JSON
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return { error: err.message };
    });
}



export function createFactura(accessToken, serie_id, factura_elemento) {
  const url = `${basePath}/${apiVersion}/factura`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      serie_id: serie_id,
      factura_elemento: factura_elemento,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function createFacturaProforma(accessToken, serie_id, factura_elemento) {
  const url = `${basePath}/${apiVersion}/factura-proforma`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      serie_id: serie_id,
      factura_elemento: factura_elemento,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFacturas(accessToken) {
  const url = `${basePath}/${apiVersion}/facturas`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getFacturasProforma(accessToken) {
  const url = `${basePath}/${apiVersion}/facturas-proforma`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export async function asociarFacturaUsuario(
  accessToken,
  formData,
  propietario_selected
) {
  const url = `${basePath}/${apiVersion}/factura-usuario/${propietario_selected}`;

  const params = {
    method: "POST",
    headers: {
      Authorization: accessToken,
    },
    body: formData,
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

// export function obtenerFacturasFecha(accessToken, idUsuario, fecha) {
//   const url = `${basePath}/${apiVersion}/facturas-usuario/${idUsuario}/${fecha}`;

//   const params = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: accessToken,
//     },
//   };

//   return fetch(url, params)
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       return err.message;
//     });
// }
export function obtenerFacturasFecha(accessToken, idUsuario, startDate, endDate) {
  const url = `${basePath}/${apiVersion}/facturas-usuario/${idUsuario}?startDate=${startDate}&endDate=${endDate}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function verFacturas(accessToken, idUsuario, nombreArchivo) {
  const url = `${basePath}/${apiVersion}/ver-factura-usuario/${idUsuario}/${nombreArchivo}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      return URL.createObjectURL(blob);
    })
    .catch(err => {
      console.error('Error al obtener el archivo:', err);
      throw err;
    });
}

export function eliminarFacturaAsociada(accessToken, idUsuario, nombreArchivo) {
  const url = `${basePath}/${apiVersion}/factura-usuario/${idUsuario}/${nombreArchivo}`;

  const params = {
    method: "DELETE",
    headers: {
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      return URL.createObjectURL(blob);
    })
    .catch(err => {
      console.error('Error al eliminar el archivo:', err);
      throw err;
    });
}
