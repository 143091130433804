import React, { useState, useEffect } from 'react';
import { Card, Collapse, Typography, Upload, Button, notification } from 'antd';  // Importa Card y Collapse de Ant Design
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { enviarFicheroABancos, ObtenerBancos } from "../../../api/bancos";
import { getAccessTokenApi } from '../../../api/auth';

const { Panel } = Collapse;
const { Text } = Typography;

const formatCurrency = (value) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(value);
};

const Bancos = ({ accesToken, monthQuery, yearQuery }) => {
  const [reservasDuplicadas, setReservasDuplicadas] = useState([]);
  const [data, setData] = useState(null);
  const [fileList, setFileList] = useState([]); // Estado para almacenar los archivos cargados

  useEffect(() => {
    const fetchBancos = async () => {
      const response = await ObtenerBancos(getAccessTokenApi());
      if (response) {
        setData(response.resultados);
      } else {
        notification.error({ message: 'Error al obtener los datos de bancos.' });
      }
    };

    // fetchBancos();
  }, [accesToken, monthQuery, yearQuery]);

  const handleFileChange = (info) => {
    let files = [...info.fileList]; // Obtiene todos los archivos cargados

    // Actualiza el estado para almacenar los archivos seleccionados
    setFileList(files);
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      notification.warning({ message: 'No has seleccionado archivos para subir.' });
      return;
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file.originFileObj); // Agrega cada archivo al FormData
    });
    try{
        let response = await enviarFicheroABancos(getAccessTokenApi(),formData);
      if (response.message) {
        notification.success({ message: 'Archivos subidos correctamente.' });
        setData(response.resultados);
        setFileList([]); // Resetea la lista de archivos si la subida fue exitosa
      } else {
        notification.error({ message: 'Error al subir los archivos.' });
      }
    } catch (error) {
      notification.error({ message: 'Error de red al subir los archivos.' });
    }
  };



  return (
    <div>
      <Upload.Dragger
        multiple={true}
        onChange={handleFileChange} // Manejador de cambio de archivos
        beforeUpload={() => false} // Previene la subida automática para manejarla manualmente
        accept=".csv,.xlsx, .xls" // Tipos de archivo permitidos
        fileList={fileList} // Asigna los archivos cargados al componente
        iconRender={(file, defaultIcon) =>
          file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
        }
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Arrastra aquí los archivos del banco</p>
        <p className="ant-upload-hint">Solo archivos Excel.</p>
      </Upload.Dragger>

      <Button
        type="primary"
        onClick={handleUpload} // Acción para subir archivos
        style={{ marginTop: 20 }}
      >
        Subir Archivos
      </Button>

      <div>
        <Card title="Resumen de Bancos" style={{ marginBottom: 16 }}>
          <Text style={{ color: "red" }} strong>Pendiente: {formatCurrency(data?.sumas?.Pendiente)}</Text><br />
          <Text strong>Conciliado: {formatCurrency(data?.sumas.Conciliado)}</Text><br />
          <Text strong>Parcial: {formatCurrency(data?.sumas.Parcial)}</Text>
        </Card>

        <Collapse defaultActiveKey={['1']}>
          <Panel header="Transacciones Pendientes" key="1">
            {data?.ordenados.Pendiente.map((item, index) => (
              <Card key={index} style={{ marginBottom: 10 }}>
                <Text>{item.Fecha} - {item.Descripción}</Text><br />
                <Text style={{ color: "red" }}>Importe: {item.Importe}</Text><br />
                <Text>Notas: {item.Notas}</Text><br />
              </Card>
            ))}
          </Panel>

          <Panel header="Transacciones Conciliadas" key="2">
            {data?.ordenados.Conciliado.map((item, index) => (
              <Card key={index} style={{ marginBottom: 10 }}>
                <Text>{item.Fecha} - {item.Descripción}</Text><br />
                <Text style={{color:"red"}}>Importe: {item.Importe}</Text><br />
              
                <Text>Notas: {item.Notas}</Text><br />
              </Card>
            ))}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Bancos;
