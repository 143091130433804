import React, { useState, useEffect } from 'react';
import { Upload, Button, notification, Card, Collapse, Col } from 'antd';  // Importa Card y Collapse de Ant Design
import { getReservasDuplicadas } from '../../../../api/reservas_duplicadas';

import { Select, Table, Typography } from 'antd';


import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import FacturacionMes from '../../FacturacionMes/FacturacionMes';
import AirbnbReservas from './Airbnb_visualizer';
const { Panel } = Collapse;

const { Option } = Select;
const { Title } = Typography;

const Duplicadas = ({ accesToken, monthQuery, yearQuery }) => {
  const [reservasDuplicadas, setReservasDuplicadas] = useState([]);
  const [total_diferencia, settotal_diferencia] = useState(0);

  const [airbnbFile, setAirbnbFile] = useState(null);
  const [bookingFile, setBookingFile] = useState(null);
  const [isDraggerVisible, setIsDraggerVisible] = useState(true); // Estado para controlar visibilidad

  // Estado para el piso seleccionado
  const [pisoSeleccionadoAirbnb, setPisoSeleccionadoAirbnb] = useState(null);
  const [pisoSeleccionadoBooking, setPisoSeleccionadoBooking] = useState(null);
  
  const [datos_Airbnb, setdatos_Airbnb] = useState(null);
  const [datos_Booking, setdatos_Booking] = useState(null);
  
  const handleAirbnbChange = (info) => 
  {
    setAirbnbFile(info.file); // Asegúrate de que el archivo esté disponible aquí
  };
  
  const handleBookingChange = (info) => 
  {
     setBookingFile(info.file); // Asegúrate de que el archivo esté disponible aquí
  };
  
  // Función para manejar el envío de archivos
  const handleSendFiles = () => {
    if (!airbnbFile) {
      notification.warning({
        message: "Archivos requeridos",
        description: "Debes subir un archivo de Airbnb.",
      });
      return;
    }
    if (!bookingFile) {
      notification.warning({
        message: "Archivos requeridos",
        description: "Debes subir un archivo de de Booking.",
      });
      return;
    }

    // Llamamos a la función getReservasDuplicadas cuando el componente se monta
    getReservasDuplicadas(accesToken, monthQuery, yearQuery, airbnbFile, bookingFile).then((response) => {
      setReservasDuplicadas(response.errores);  // Guardamos los errores (reservas duplicadas)
      settotal_diferencia(response.total_diferencia);
      setdatos_Airbnb(response?.datos_Airbnb)
      setdatos_Booking(response?.datos_Booking)
      
      // console.log("datos_Airbnb", response.datos_Airbnb)
      notification["success"]({
        message: "Información de Reservas",
        description: "Se encontraron reservas duplicadas.",  // Puedes personalizar este mensaje
      });
      setIsDraggerVisible(false)

    }).catch((error) => {
      notification["error"]({
        message: "Error al obtener reservas duplicadas",
        description: error.message,
      });
    });

    notification.success({
      message: 'Archivos enviados',
      description: 'Los archivos fueron enviados correctamente.',
    });

  };

  useEffect(() => {
    
  }, [accesToken, monthQuery, yearQuery]);

  // Extraer y ordenar los nombres de los pisos
  const nombresPisosAirbnb = Object.keys(datos_Airbnb??{}).sort();
  const nombresPisosBooking = Object.keys(datos_Booking??{}).sort();

  // Manejar el cambio en el select
  const handlePisoChangeAirbnb = (piso) => {
    setPisoSeleccionadoAirbnb(piso);
  };

    // Manejar el cambio en el select
    const handlePisoChangeBooking = (piso) => {
      setPisoSeleccionadoBooking(piso);
    };

    
  return (
    <>
    <Collapse>
      <Collapse.Panel header="Reservas Duplicadas">
        <div>
          <h2>Reservas Duplicadas {total_diferencia??""}</h2>

          {reservasDuplicadas && reservasDuplicadas.length > 0 ? (
            reservasDuplicadas.map((reserva, index) => (
              <Card
                key={index}
                title={`Discrepancia ${index + 1}: `}
                style={{ marginBottom: 16 }}
              >
                <span>{reserva.error}</span>
                
                <Collapse>
                
                  <Panel header="Detalles CSV" key="2">
                    <pre>{reserva.detallesCSV}</pre>
                  </Panel>
                  <Panel header="Detalles en Plataforma" key="3">
                    <pre>{reserva.detallesPlataforma}</pre>
                  </Panel>
                  <Panel header="Vista Mes Piso" key="4">
                    <FacturacionMes 
                      mes={monthQuery} 
                      year={yearQuery} 
                      inmueble={reserva.inmueble} 
                      busqueda_automatica_al_inicio={true}>
                    </FacturacionMes>
                  </Panel>
                </Collapse>
              </Card>
            ))
          ) : (
            <p> No hay reservas duplicadas. </p>
          )}
        {/* Zona de arrastrar y soltar para archivos de Airbnb */}
    <h3>Subir archivos de Airbnb.</h3>
    {isDraggerVisible?
      <>
        <Upload.Dragger
          multiple={false}
          onChange={handleAirbnbChange}
          beforeUpload={() => false} // Previene la subida automática para manejarla manualmente
          accept=".csv,.xls" // Tipos de archivo permitidos
          style={{ marginBottom: 20 }}
          iconRender={(file, defaultIcon) =>
            file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
          }
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Arrastra aquí el archivo de Airbnb</p>
          <p className="ant-upload-hint">Solo archivos CSV o Excel.</p>
        </Upload.Dragger>

        <Upload.Dragger
          multiple={false}
          onChange={handleBookingChange}
          beforeUpload={() => false} // Previene la subida automática para manejarla manualmente
          accept=".csv,.xls" // Tipos de archivo permitidos
          style={{ marginBottom: 20 }}
          iconRender={(file, defaultIcon) =>
            file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
          }
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Arrastra aquí el archivo de Booking</p>
          <p className="ant-upload-hint">Solo archivos CSV o Excel.</p>
        </Upload.Dragger>    
    </> 
      : 
    <></>
    }

          {/* Botón de enviar */}
          <Button type="primary" onClick={handleSendFiles}>
            Enviar Archivos
          </Button>
          
        </div>
    </Collapse.Panel>
   </Collapse>
    {/* Select para elegir el piso */}
    <Select
        style={{ width: 300, marginBottom: 20 }}
        placeholder="Selecciona un piso Airbnb"
        onChange={handlePisoChangeAirbnb}
      >
        {nombresPisosAirbnb.map((piso) => (
          <Option key={piso} value={piso}>
            {piso}
          </Option>
        ))}
      </Select>
      <Select
        style={{ width: 300, marginBottom: 20 }}
        placeholder="Selecciona un piso Booking"
        onChange={handlePisoChangeBooking}
      >
        {nombresPisosBooking.map((piso) => (
          <Option key={piso} value={piso}>
            {piso}
          </Option>
        ))}
      </Select>
      
      <Collapse>
        <Collapse.Panel header="Fichero Airbnb">
            <div>
              <h2>Fichero Airbnb</h2>
              
                <AirbnbReservas datos_Airbnb={datos_Airbnb} inmueble={pisoSeleccionadoAirbnb}/>

            </div>
        </Collapse.Panel>

        <Collapse.Panel header="Fichero Booking">
            <div>
              <h2>Fichero Booking</h2>
              <AirbnbReservas datos_Airbnb={datos_Booking} inmueble={pisoSeleccionadoBooking}/>
            
            </div>
        </Collapse.Panel>
        {/* <Collapse.Panel header="PLATAFORMA">
            <div>
              <h2>Plataforma</h2>
              <FacturacionMes mes={monthQuery} year={yearQuery} inmueble={pisoSeleccionadoAirbnb??""} busqueda_automatica_al_inicio={true}/>
            
            </div>
        </Collapse.Panel> */}
    </Collapse>
    </>
  );
};

export default Duplicadas;
